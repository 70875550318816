import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography, TextField } from '@mui/material';

// APIs
import { getRecipe } from '../../../api';

// Components
import { BackToButton, LineItem } from '@lexcelon/react-util';

import { setError } from '../../../alerts';

class Recipe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      recipe: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.retrieveRecipe();
  }

  retrieveRecipe = () => {
    // Retrieve the recipe id from the url
    const recipeId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getRecipe(recipeId).then(recipe => {
      this.setState({ isLoading: false, recipe });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve recipe info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onSuccess = () => {
    this.retrieveRecipe();
  }

  render() {
    return this.state.redirect ? <Redirect to={this.props.location?.state?.backTo?.pathname ?? '/'} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Recipe</Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <LineItem
              description='ID'
              value={this.state.recipe?.getId()}
            />

            <LineItem
              description='Release Date'
              value={this.state.recipe?.getReleaseDate()?.toLocaleString()}
            />

            <LineItem
              description='Test Type'
              value={`${this.state.recipe?.getTestType()?.getName()} [${this.state.recipe?.getTestType()?.getIsDemo() ? 'Training' : 'Normal'}]`}
            />

            <LineItem
              description='Continent'
              value={this.state.recipe?.getContinent()}
            />

            <TextField
              label='Recipe Script'
              fullWidth
              multiline
              rows={30}
              style={{ marginTop: '0.5em' }}
              value={this.state.recipe?.getScriptContent()}
              variant='filled'
              disabled={true}
            />
          </>
        )
        }
      </Container>
    );
  }
}

Recipe.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Recipe);
