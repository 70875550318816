import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

// Pages
import Account from '../pages/account';

import AccountingMonthlyCounts from '../pages/accounting/monthly-counts';

import InviteAdminUser from '../pages/admin-users/invite';
import AdminUser from '../pages/admin-users/[id]';
import AdminUsers from '../pages/admin-users';

import CreateAccount from '../pages/auth/create-account/[token]';
import ForgotPasswordConfirm from '../pages/auth/forgot-password/confirm/[token]';
import ForgotPasswordRequest from '../pages/auth/forgot-password/request';
import Login from '../pages/auth/login';

import CreatePractice from '../pages/practices/create';
import PracticeReporting from '../pages/practices/reporting';
import CreateUser from '../pages/practices/[id]/create-user';
import PracticeInventory from '../pages/practices/[id]/inventory';
import PracticeTests from '../pages/practices/[id]/tests';
import PracticeErrorLog from '../pages/practices/[id]/error-log';
import PracticeMonthlyTests from '../pages/practices/[id]/monthly-tests';
import Practice from '../pages/practices/[id]';
import Practices from '../pages/practices';

import CreateRecipe from '../pages/recipes/create';
import Recipe from '../pages/recipes/[id]';
import Recipes from '../pages/recipes';

import TestType from '../pages/tests/types/[id]';
import TestTypes from '../pages/tests/types';
import TestsReporting from '../pages/tests/reporting';
import TestsSummary from '../pages/tests/summary';
import TestsBatchDownload from '../pages/tests/batch-download';
import TestAudit from '../pages/tests/[id]/audit';
import Test from '../pages/tests/[id]';
import Tests from '../pages/tests';

import Instruments from '../pages/instruments';

import Triggers from '../pages/triggers';

import User from '../pages/users/[id]';
import Users from '../pages/users';
import AuthRedirect from './AuthRedirect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeRedirect from './HomeRedirect';
import KnownErrors from '../pages/errors/known';
import ErrorLog from '../pages/errors/log';

import InstrumentCameraParams from '../pages/instruments/[serialNumber]/camera-params';
import InstrumentShuttleSettings from '../pages/instruments/[serialNumber]/shuttle-settings';
import InstrumentSensorSettings from '../pages/instruments/[serialNumber]/sensor-settings';
import InstrumentPumpSettings from '../pages/instruments/[serialNumber]/pump-settings';
import InstrumentLogs from '../pages/instruments/[serialNumber]/logs';
import InstrumentSensors from '../pages/instruments/[serialNumber]/sensors';
import Instrument from '../pages/instruments/[serialNumber]';

import Inventory from '../pages/inventory';

import PlatformSettings from '../pages/platform-settings';

class RouteAndWrapper extends Component {
  render() {
    return (
      <Route path={this.props.path} exact={this.props.exact}>
        {AuthRedirect(this.props.location.pathname, this.props.authProps) || this.props.children}
      </Route>
    );
  }
}

RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object.isRequired,
  authProps: PropTypes.exact({
    isLoading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }).isRequired
};

const routeAndWrapperMapStateToProps = state => ({
  authProps: state.auth
});

const ConnectedRouteAndWrapper = connect(routeAndWrapperMapStateToProps, null)(withRouter(RouteAndWrapper));

export default class Navigation extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '120px' }}>
        <Switch>
          <ConnectedRouteAndWrapper path='/account'>
            <Account />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/accounting/monthly-counts' >
            <AccountingMonthlyCounts />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/admin-users/invite' >
            <InviteAdminUser />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin-users/:id' >
            <AdminUser />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin-users' >
            <AdminUsers />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/auth/create-account/:token'>
            <CreateAccount />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password/confirm/:token'>
            <ForgotPasswordConfirm />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password/request'>
            <ForgotPasswordRequest />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/login'>
            <Login />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/errors/known' >
            <KnownErrors />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/errors/log' >
            <ErrorLog />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/camera-params' >
            <InstrumentCameraParams />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/sensor-settings' >
            <InstrumentSensorSettings />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/shuttle-settings' >
            <InstrumentShuttleSettings />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/pump-settings' >
            <InstrumentPumpSettings />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/sensors' >
            <InstrumentSensors />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/logs' >
            <InstrumentLogs />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber' >
            <Instrument />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/inventory' >
            <Inventory />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/platform-settings' >
            <PlatformSettings />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/practices/create' >
            <CreatePractice />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/reporting' >
            <PracticeReporting />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id/create-user' >
            <CreateUser />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id/inventory' >
            <PracticeInventory />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id/tests' >
            <PracticeTests />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id/error-log' >
            <PracticeErrorLog />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id/monthly-tests' >
            <PracticeMonthlyTests />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices/:id' >
            <Practice />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/practices' >
            <Practices />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/recipes/create' >
            <CreateRecipe />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/recipes/:id' >
            <Recipe />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/recipes' >
            <Recipes />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/tests/types/:id' >
            <TestType />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/types' >
            <TestTypes />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/reporting' >
            <TestsReporting />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/summary' >
            <TestsSummary />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/batch-download' >
            <TestsBatchDownload />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/:id/audit' >
            <TestAudit />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/:id' >
            <Test />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests' >
            <Tests />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/instruments' >
            <Instruments />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/triggers' >
            <Triggers />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/users/:id' >
            <User />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/users' >
            <Users />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/'>
            <HomeRedirect />
          </ConnectedRouteAndWrapper>
        </Switch>
      </div>
    );
  }
}
