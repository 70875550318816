import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography } from '@mui/material';

// APIs
import { getAdminUser, suspendAdminUser, reactivateAdminUser } from '../../../api';

// Components
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';
import { AdminUserForm } from '../../../components';

import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';


class AdminUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      adminUser: null,
      inEditMode: false,
    };
  }

  componentDidMount() {
    this.retrieveAdminUser();
  }

  retrieveAdminUser = () => {
    // Retrieve the adminUser id from the url
    const adminUserId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getAdminUser(adminUserId).then(adminUser => {
      this.setState({ isLoading: false, adminUser });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve admin user info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  suspendConfirm = () => {
    confirm({
      title: 'Suspend Admin User',
      body: `Are you sure you want to suspend the admin user ${this.state.adminUser.getFirstName()} ${this.state.adminUser.getLastName()}?`,
      onConfirm: () => {
        startConfirmLoading();
        suspendAdminUser(this.state.adminUser.getId()).then((adminUser) => {
          setSuccess('Successfully suspended admin user!');
          this.setState({ adminUser });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to suspend admin user.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  reactivateConfirm = () => {
    confirm({
      title: 'Reactivate Admin User',
      body: `Are you sure you want to reactivate the admin user ${this.state.adminUser.getFirstName()} ${this.state.adminUser.getLastName()}?`,
      onConfirm: () => {
        startConfirmLoading();
        reactivateAdminUser(this.state.adminUser.getId()).then((adminUser) => {
          setSuccess('Successfully reactivated admin user!');
          this.setState({ adminUser });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to reactivate admin user.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onSuccess = () => {
    this.retrieveAdminUser();
    this.closeEditMode();
  }

  render() {
    var isSuspended = this.state.adminUser?.getIsSuspended();
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{`Admin User: ${this.state.adminUser?.getFirstName()} ${this.state.adminUser?.getLastName()}`}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '2em', color: isSuspended ? '#d32f2f' : 'black' }}>{isSuspended ? 'SUSPENDED' : ('ACTIVE')}</Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <AdminUserForm
              onSuccess={this.onSuccess}
              onClose={this.closeEditMode}
              adminUser={this.state.adminUser}
            />
          ) : (
            <>
              <LineItem
                value={this.state.adminUser?.getFirstName() + ' ' + this.state.adminUser?.getLastName()}
                description='Name'
              />

              <LineItem
                value={this.state.adminUser?.getEmail()}
                description='Email'
              />

              <div style={{ marginTop: '50px' }}>
                <Button secondary onClick={this.openEditMode}>Edit Admin User</Button>
              </div>

              {isSuspended ? (
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={this.reactivateConfirm}>Reactivate Admin User</Button>
                </div>
              ) : (
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={this.suspendConfirm}>Suspend Admin User</Button>
                </div>
              )
              }
            </>

          )
        )
        }
      </Container>
    );
  }
}

AdminUser.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(AdminUser);
