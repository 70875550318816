import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// APIs
import { updateAdminUser } from '../../api';

import { setError, setSuccess } from '../../alerts';

class AdminUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    // If this is an edit form, pre-fill the form
    if (this.props.adminUser != null) {
      this.setState({
        firstName: this.props.adminUser.getFirstName() ?? '',
        lastName: this.props.adminUser.getLastName() ?? '',
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      firstName,
      lastName,
    } = this.state;

    // In Edit Mode
    if (this.props.adminUser != null) {
      updateAdminUser({ id: this.props.adminUser.getId(), firstName, lastName }).then(adminUser => {
        setSuccess('Successfully updated adminUser!');
        this.setState({ isLoading: false });
        this.props.onSuccess(adminUser);
      }).catch(error => {
        setError(error ?? 'Error: Unable to update adminUser. Please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <>

          {/* Name */}
          <Grid container direction='row' align-items='space-between' rowSpacing={0} columnSpacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                name='firstName'
                label='First Name'
                value={this.state.firstName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                fullWidth
                style={{ marginBottom: '0.5em' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                name='lastName'
                label='Last Name'
                value={this.state.lastName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                fullWidth
                style={{ marginBottom: '0.5em' }}
              />
            </Grid>
          </Grid>
        </>

        {/* Form Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
            <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
              Cancel
            </Button>}

          {/* Submit Button */}
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '48%' }}>
            Submit
          </Button>
        </div>
      </form >
    );
  }
}

AdminUserForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  adminUser: PropTypes.object,
  address: PropTypes.object,
  onClose: PropTypes.func
};

export default AdminUserForm;
