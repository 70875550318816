import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clearErrors, setError, setSuccess } from '../alerts';

// Components
import { TextField, CircularProgress, List, ListItem, ListItemText, MenuItem } from '@mui/material';
import { Button } from '@lexcelon/react-util';

class InstrumentSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingData: true,
      settings: null,
      inEditMode: false
    };
    this.hasMountedAlready = false;
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* necessary to avoid reloading in dev */)) {
      this.retrieveSettings();
    }
    this.hasMountedAlready = true;
  }

  componentWillUnmount() {
    clearErrors();
  }

  retrieveSettings = () => {
    this.setState({ isLoadingData: true });

    this.props.getSettings(this.props.serialNumber).then((settings) => {
      this.setState({ isLoadingData: false, settings });
    }).catch((error) => {
      setError(error ?? 'Error retrieving settings');
      this.setState({ isLoadingData: false });
    });
  };

  save = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    this.props.setSettings(this.props.serialNumber, this.state.settings).then(() => {
      setSuccess('Settings saved successfully');
      this.setState({ inEditMode: false, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error saving settings');
      this.setState({ isLoading: false });
    });
  }

  render() {
    if (this.state.redirect) return <Redirect to='/' />;
    return (
      <>
        {this.state.isLoadingData && <CircularProgress style={{ display: 'block', margin: 'auto' }} />}

        {this.state.settings != null &&
        <form onSubmit={this.save}>
          <List>
            {this.props.settings.map((setting, index) => (
              <ListItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#D3D3D3' : '#F2F2F2' }}>
                <ListItemText primary={setting.label} style={{ fontWeight: 'bold' }} />

                {this.state.inEditMode ? (
                  setting.type === 'number' ? (
                    <TextField
                      required
                      value={this.state.settings[setting.key]}
                      onChange={(e) => this.setState({ settings: { ...this.state.settings, [setting.key]: e.target.value } })}
                      variant='filled'
                      disabled={this.state.isLoadingData || this.state.isLoading || setting.disabled}
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      style={{ width: '200px' }}
                    />
                  ) : (
                    <TextField
                      required
                      select
                      value={this.state.settings[setting.key]}
                      onChange={(e) => this.setState({ settings: { ...this.state.settings, [setting.key]: e.target.value } })}
                      variant='filled'
                      disabled={this.state.isLoadingData || this.state.isLoading || setting.disabled}
                      style={{ width: '200px' }}>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TextField>
                  )
                ) : (
                  <ListItemText primary={setting.key in this.state.settings ? (
                    setting.type === 'number' ? this.state.settings[setting.key] : (
                      this.state.settings[setting.key] ? 'True' : 'False'
                    )
                  ) : ''} style={{ textAlign: 'right' }} />
                )}
              </ListItem>
            ))}
          </List>

          {this.state.inEditMode &&
          <Button type='submit' color='primary' style={{ marginTop: '1em', marginRight: '10px' }} disabled={this.state.isLoadingData} isLoading={this.state.isLoading}>
            Save
          </Button>}

          <Button color={this.state.inEditMode ? 'secondary' : 'primary'} style={{ marginTop: '1em' }} onClick={() => this.setState({ inEditMode: !this.state.inEditMode })} disabled={this.state.isLoadingData || this.state.isLoading}>
            {this.state.inEditMode ? 'Cancel' : 'Edit'}
          </Button>
        </form>}
      </>
    );
  }
}

InstrumentSettingsForm.propTypes = {
  serialNumber: PropTypes.string.isRequired,
  getSettings: PropTypes.func.isRequired,
  setSettings: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['number', 'boolean']).isRequired,
    disabled: PropTypes.bool
  })).isRequired
};

export default withRouter(InstrumentSettingsForm);
