import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// Components
import { Typography } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';

// Api
import { listInventoryTypes, listPracticesWithInventory } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// Alerts
import { setError } from '../../alerts';

class Inventory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      inventoryTypes: [],
    };
  }

  componentDidMount() {
    listInventoryTypes().then(inventoryTypes => {
      this.setState({ inventoryTypes });
    }).catch(error => {
      setError(error ?? 'Unable to load inventory types');
    });
  }

  render() {
    const backTo = { pathname: { pathname: '/inventory', state: { tableSearchString: this.state.searchString } }, description: 'All Inventory' };
    const COLUMNS = [
      {
        title: 'Identifier',
        remoteField: 'identifier',
        value: rowData => rowData.getIdentifier(),
        search: 'column'
      },
      {
        title: 'Name',
        remoteField: 'name',
        value: rowData => rowData.getName(),
        search: 'column'
      },
      ...this.state.inventoryTypes.map(inventoryType => ({
        title: inventoryType.getName(),
        value: rowData => rowData.getPracticeInventoryTypes()?.find(practiceInventoryType => practiceInventoryType.getInventoryTypeId() === inventoryType.getId())?.getCount(),
        search: 'none',
        customSort: null
      })),
      {
        title: 'Actions',
        omitFromExport: true,
        render: (rowData) => (
          <Link to={{ pathname: `/practices/${rowData.getId()}/inventory`, state: { backTo } }}><VisibilityIcon /></Link>
        )
      },
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Inventory</Typography>

        <Typography variant='button' style={{ color: 'red' }}>Note: Internal and suspended practices are omitted from below results.</Typography>

        <MaterialTable
          title='Inventory'
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where: {
                    ...where,
                    isInternal: false,
                    isSuspended: false
                  },
                  order,
                };
                listPracticesWithInventory({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />
      </div>
    );
  }
}

Inventory.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Inventory);
