import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// Components
import { Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listPractices } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AddIcon from '@mui/icons-material/Add';

// Constants
const red = '#d32f2f';

class Practices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filterOutInternal: true,
      searchString: null,
    };
  }

  render() {
    const backTo = { pathname: { pathname: '/practices', state: { tableSearchString: this.state.searchString } }, description: 'All Practices' };
    const COLUMNS = [
      {
        title: 'Identifier',
        remoteField: 'identifier',
        value: rowData => rowData.getIdentifier(),
        search: 'column'
      },
      {
        title: 'Name',
        remoteField: 'name',
        value: rowData => rowData.getName(),
        search: 'column'
      },
      {
        title: 'Location',
        value: rowData => (rowData.getAddress()?.getCityStateZipCountry())
      },
      {
        title: 'Suspended',
        remoteField: 'isSuspended',
        value: rowData => rowData.getIsSuspended(),
        render: (rowData) => {
          return (rowData.isSuspended ? (<span style={{ color: red }}>Suspended</span>) : (<span style={{ color: 'green' }}>Active</span>));
        },
        search: 'select',
        selectOptions: [{ value: true, label: 'Suspended' }, { value: false, label: 'Active' }]
      },
      {
        title: 'Internal',
        remoteField: 'isInternal',
        value: rowData => rowData.getIsInternal(),
        render: (rowData) => {
          return (rowData.isInternal ? (<span style={{ color: 'orange' }}>Internal</span>) : (<span style={{ color: 'green' }}>External</span>));
        },
        search: 'select',
        selectOptions: [{ value: true, label: 'Internal' }, { value: false, label: 'External' }]
      },
      {
        title: 'Actions',
        omitFromExport: true,
        render: (rowData) => (
          <Link to={{ pathname: `/practices/${rowData.getId()}`, state: { backTo } }}><VisibilityIcon /></Link>
        )
      },
      {
        title: 'Logs',
        omitFromExport: true,
        render: (rowData) => <Link to={{ pathname: `/practices/${rowData.getId()}/error-log`, state: { backTo } }}><HistoryEduIcon /></Link>,
      },
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Practices</Typography>

        <FormGroup>
          <FormControlLabel control={<Switch checked={this.state.filterOutInternal} onChange={e => this.setState({ filterOutInternal: e.target.checked })} />} label='Filter out internal practices' />
        </FormGroup>
        <MaterialTable
          title='Practices'
          headerContent={
            <Button component={Link} to={{ pathname: '/practices/create', state: { backTo } }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where: {
                    ...where,
                    ...(this.state.filterOutInternal ? { isInternal: false } : {})
                  },
                  order,
                };
                listPractices({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />
      </div>
    );
  }
}

Practices.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Practices);
