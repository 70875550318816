import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import { ActivePracticesGraph } from '../../../components';

class PracticeReporting extends Component {
  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Practice Reporting</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: 'red', fontWeight: 'bold', marginBottom: '1em' }}>Note: This page may be inaccurate. Please do not use for reporting or billing purposes.</Typography>

        <ActivePracticesGraph title='Top 20 Active Practices' max={20} />
      </Container>
    );
  }
}

PracticeReporting.propTypes = {
};

export default PracticeReporting;
