import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Typography, Button, Grid, DialogActions
} from '@mui/material';
import { setError, setSuccess } from '../alerts';
import { updatePracticeInventoryType } from '../api';

class EditInventoryTypeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      orderQuantity: 0,
      orderTriggerNumber: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen && this.props.practiceInventoryType != null) {
      this.setState({ 
        count: this.props.practiceInventoryType.getCount(), 
        orderQuantity: this.props.practiceInventoryType.getOrderQuantity(), 
        orderTriggerNumber: this.props.practiceInventoryType.getOrderTriggerNumber() 
      });
    }
  }

  handleIncrement = (field, incrementBy) => {
    this.setState((prevState) => ({
      [field]: prevState[field] + incrementBy
    }));
  };

  handleDecrement = (field, min, incrementBy) => {
    if (this.state[field] <= min) return;
    this.setState((prevState) => ({
      [field]: prevState[field] - incrementBy
    }));
  };

  handleSave = () => {
    this.props.onClose();
    const { count, orderQuantity, orderTriggerNumber } = this.state;
    updatePracticeInventoryType({ id: this.props.practiceInventoryType.getId(), count, orderQuantity, orderTriggerNumber }).then(practiceInventoryType => {
      this.props.onClose(practiceInventoryType);
      setSuccess('Successfully updated inventory type.');
    }).catch(error => {
      this.props.onClose();
      setError(error ?? 'Error: Unable to update inventory type.');
    });
  };

  renderValueSection = (title, field, min, incrementBy = 1) => (
    <Grid container alignItems='center' spacing={2} style={{ marginBottom: '20px' }}>
      <Grid item xs={4}>
        <Typography variant='h6'>{title}</Typography>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <Button variant='contained' disabled={this.state[field] <= min} onClick={() => this.handleDecrement(field, min, incrementBy)}>-</Button>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Typography variant='h6'>{this.state[field]}</Typography>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'left' }}>
        <Button variant='contained' onClick={() => this.handleIncrement(field, incrementBy)}>+</Button>
      </Grid>
    </Grid>
  );

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog open={isOpen} fullWidth>
        <DialogTitle>Edit Inventory Type</DialogTitle>
        <DialogContent>
          {this.renderValueSection('Count', 'count', 0, this.props.practiceInventoryType?.getInventoryType()?.getIsTestKit() ? 50 : 1)}
          {this.renderValueSection('Order Quantity', 'orderQuantity', 1)}
          {this.renderValueSection('Order Trigger Number', 'orderTriggerNumber', this.props.practiceInventoryType?.getInventoryType()?.getMinimumOrderTriggerNumber() ?? 1)}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.handleSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditInventoryTypeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  practiceInventoryType: PropTypes.object,
};

export default EditInventoryTypeDialog;