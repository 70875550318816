import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Container, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import { Button, BackToButton, LineItem } from '@lexcelon/react-util';
import { generatePassword } from '../../../util';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

import { getUser, suspendUser, reactivateUser, resetUserPassword, updateUserPassword } from '../../../api';

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      inChangePassword: false,
      isLoading: false,
      password: generatePassword(),
      showPassword: true,
    };
  }

  componentDidMount() {
    const userId = this.props.match?.params?.id;
    getUser(userId).then(user => {
      this.setState({ user });
    }).catch(error => {
      setError(error ?? 'Error: Unable to get user.');
    });
  }

  confirmSuspend = () => {
    confirm({
      title: `Are you sure you want to suspend the user ${this.state.user.getFullName()}?`,
      body: 'They will immediately be logged out and unable to perform tests.',
      onConfirm: () => {
        startConfirmLoading();
        suspendUser(this.state.user.getId()).then((user) => {
          setSuccess('Successfully suspended user!');
          this.setState({ user });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to suspend user.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  confirmReactivate = () => {
    confirm({
      title: `Are you sure you want to reactivate the user ${this.state.user.getFullName()}?`,
      body: 'They will immediately be able to log in and perform tests.',
      onConfirm: () => {
        startConfirmLoading();
        reactivateUser(this.state.user.getId()).then((user) => {
          setSuccess('Successfully reactivated user!');
          this.setState({ user });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to reactivate user.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  confirmPasswordReset = () => {
    confirm({
      title: `Are you sure you want to require the user ${this.state.user.getFullName()} to change their password on their next login?`,
      body: 'They will be required to change their password.',
      onConfirm: () => {
        startConfirmLoading();
        resetUserPassword(this.state.user.getId()).then(() => {
          setSuccess('Successfully required password reset!');
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to require password reset.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  changePassword = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    updateUserPassword(this.state.user.getId(), { password: this.state.password }).then((user) => {
      setSuccess('Successfully changed password!');
      this.setState({ isLoading: false, inChangePassword: false, user });
    }).catch(error => {
      setError(error ?? 'Error: Unable to change password.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>User: {this.state.user?.getFullName()}</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginTop: '0.3em', fontWeight: 'bold', color: this.state.user?.getIsSuspended() ? 'red' : 'green' }}>{this.state.user?.getIsSuspended() ? 'Suspended' : 'Active'}</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>Personal Information</Typography>
        <>
          {/* Name */}
          <LineItem
            value={this.state.user?.getFirstName() + ' ' + this.state.user?.getLastName()}
            description='Name'
          />

          {/* Email */}
          <LineItem
            value={this.state.user?.getEmail()}
            description='Email'
          />

          {/* Practice Info */}
          <LineItem
            value={
              <Link to={{ pathname: `/practices/${this.state.user?.getPractice()?.getId()}`, state: { backTo: { pathname: this.props.location, description: this.state.user?.getFullName() ?? 'User' } } }}>{this.state.user?.getPractice()?.getName()}</Link>
            }
            description='Practice'
          />
        </>

        {/* Buttons */}
        {this.state.inChangePassword ? (
          <form onSubmit={this.changePassword}>
            {/* Password */}
            <TextField
              required
              name='password'
              label='Password'
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              variant='filled'
              disabled={this.state.isLoading}
              fullWidth
              helperText='Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              style={{ marginBottom: '0.5em' }}
            />
            <Button type='submit' isLoading={this.state.isLoading}>Change Password</Button>
          </form>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            <Button onClick={() => this.setState({ inChangePassword: true })} style={{ marginBottom: '10px' }}>Change Password</Button>
            {this.state.user != null &&
            <div style={{ marginBottom: '10px', width: '100%' }}>
              {this.state.user.getIsSuspended() ? (
                <Button danger onClick={this.confirmReactivate} style={{ width: '100%' }}>Reactivate User</Button>
              ) : (
                <Button danger onClick={this.confirmSuspend} style={{ width: '100%' }}>Suspend User</Button>
              )}
            </div>}
            <Button danger onClick={this.confirmPasswordReset}>Require Password Change on Login</Button>
          </div>
        )}
        
      </Container>
    );
  }
}

User.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(User);
