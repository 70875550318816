import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MaterialTable } from '@lexcelon/react-util';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

const red = '#d32f2f';
const USER_COLUMNS = ({ locationState }) => ([
  {
    title: 'First Name',
    remoteField: 'firstName',
    value: rowData => rowData.firstName,
    search: 'column'
  },
  {
    title: 'Last Name',
    remoteField: 'lastName',
    value: rowData => rowData.lastName,
    search: 'column'
  },
  {
    title: 'Email',
    remoteField: 'email',
    value: rowData => rowData.getEmail(),
    search: 'column'
  },
  {
    title: 'Suspended',
    remoteField: 'isSuspended',
    value: rowData => rowData.getIsSuspended(),
    render: (rowData) => {
      return (rowData.isSuspended ? (<span style={{ color: red }}>Suspended</span>) : (<span style={{ color: 'green' }}>Active</span>));
    },
    search: 'select',
    selectOptions: [{ value: true, label: 'Suspended' }, { value: false, label: 'Active' }]
  },
  {
    title: 'View',
    value: () => '',
    render: (rowData) => (
      <Link to={{ pathname: `/users/${rowData.getId()}`, state: locationState }}><VisibilityIcon /></Link>
    )
  }
]);

export default function UsersTable({ getUsers, headerContent, practiceId, locationState = {} }) {
  return (
    <MaterialTable
      title='Users'
      headerContent={headerContent}
      data={{
        mode: 'remote',
        columns: USER_COLUMNS({ locationState }),
        fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>
          new Promise((resolve, reject) => {
            let options = {
              offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
              limit: rowsPerPage,
              where: practiceId != null ? { ...where, practiceId: practiceId } : where,
              order,
            };
            getUsers({ options }).then((res) => {
              resolve(res);
            }).catch((error) => {
              reject(error);
            });
          })
      }}
      options={{
        pageSize: 20,
        flipHorizontalScroll: true,
      }}
    />
  );
}

UsersTable.propTypes = {
  getUsers: PropTypes.func.isRequired,
  practiceId: PropTypes.string,
  headerContent: PropTypes.element,
  locationState: PropTypes.object
};
