import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import { InstrumentSettingsForm } from '../../../../components';

import { getCameraParams, setCameraParams } from '../../../../api';

// Values can be number or boolean
const SETTINGS = [
  {
    label: 'Exposure Time',
    key: 'exposure',
    type: 'number'
  },
  {
    label: 'Gain',
    key: 'gain',
    type: 'number'
  },
  {
    label: 'Horizontal Image Flip',
    key: 'hflip',
    type: 'boolean'
  },
  {
    label: 'Vertical Image Flip',
    key: 'vflip',
    type: 'boolean'
  },
];

class InstrumentCameraParams extends Component {

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>Instrument Camera Params</Typography>

        <InstrumentSettingsForm
          settings={SETTINGS}
          getSettings={getCameraParams}
          setSettings={setCameraParams}
          serialNumber={this.props.match?.params?.serialNumber}
        />
      </Container>
    );
  }
}

InstrumentCameraParams.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentCameraParams);
