import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import SpeciesTestsChart from '../../../components/TestsReportingCharts/SpeciesTestsChart';
import YearOverYearTestsGraph from '../../../components/TestsReportingCharts/YearOverYearTestsGraph';

class TestsReporting extends Component {
  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Tests Reporting</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: 'red', fontWeight: 'bold', marginBottom: '0.5em' }}>Note: This page may be inaccurate. Please do not use for reporting or billing purposes.</Typography>
        <YearOverYearTestsGraph title='Year Over Year Tests' />
        <SpeciesTestsChart title='Test Count by Species' />
      </Container>
    );
  }
}

TestsReporting.propTypes = {
};

export default TestsReporting;
