import React from 'react';
import PropTypes from 'prop-types';

// API
import { updatePassword } from '../../api';

// Alerts
import { setError } from '../../alerts';

// Lib
import { AdminUser } from '@parasightsysteminc/companion-react';

// Components
import { Button } from '@lexcelon/react-util';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class PasswordChangeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isLoading: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      setError('Passwords do not match. Please try again.');
      return;
    }

    // Start loading
    this.setState({ isLoading: true });

    const {
      currentPassword,
      newPassword
    } = this.state;

    updatePassword({ password: currentPassword, newPassword }).then((user) => {
      this.setState({ isLoading: false });
      this.props.onSuccess(user);
    }).catch((err) => {
      setError(err ?? 'Error: Unable to update password. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          required
          name='currentPassword'
          label='Current Password'
          type={this.state.showPassword ? 'text' : 'password'}
          value={this.state.currentPassword}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          fullWidth
          sx={{ mr: '.5em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          style={{ marginBottom: '0.5em' }}
        />

        <TextField
          required
          name='newPassword'
          label='New Password'
          type={this.state.showPassword ? 'text' : 'password'}
          value={this.state.newPassword}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          fullWidth
          sx={{ mr: '.5em' }}
          helperText='Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          style={{ marginBottom: '0.5em' }}
        />

        <TextField
          required
          name='confirmNewPassword'
          label='Confirm New Password'
          type={this.state.showPassword ? 'text' : 'password'}
          value={this.state.confirmNewPassword}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{ mr: '.5em' }}
        />

        {/* Form Buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
            <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
              Cancel
            </Button>}

          {/* Submit Button */}
          <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

PasswordChangeForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(AdminUser),
  onClose: PropTypes.func
};

export default PasswordChangeForm;