import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';
import VisibilityIcon from '@mui/icons-material/Visibility';

// APIs
import { listUsers } from '../../api';

const red = '#d32f2f';
const USER_COLUMNS = ({ locationState }) => ([
  {
    title: 'First Name',
    remoteField: 'firstName',
    value: rowData => rowData.firstName,
    search: 'column'
  },
  {
    title: 'Last Name',
    remoteField: 'lastName',
    value: rowData => rowData.lastName,
    search: 'column'
  },
  {
    title: 'Email',
    remoteField: 'email',
    value: rowData => rowData.getEmail(),
    search: 'column'
  },
  {
    title: 'Suspended',
    remoteField: 'isSuspended',
    value: rowData => rowData.getIsSuspended(),
    render: (rowData) => {
      return (rowData.isSuspended ? (<span style={{ color: red }}>Suspended</span>) : (<span style={{ color: 'green' }}>Active</span>));
    },
    search: 'select',
    selectOptions: [{ value: true, label: 'Suspended' }, { value: false, label: 'Active' }]
  },
  {
    title: 'View',
    value: () => '',
    render: (rowData) => (
      <Link to={{ pathname: `/users/${rowData.getId()}`, state: locationState }}><VisibilityIcon /></Link>
    )
  }
]);

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: null,
    };
  }

  render() {
    const locationState = {
      backTo: { pathname: { pathname: '/users', state: { tableSearchString: this.state.searchString } }, description: 'All Users' }
    };
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Users</Typography>

          <MaterialTable
            title='Users'
            data={{
              mode: 'remote',
              columns: USER_COLUMNS({ locationState }),
              fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>
                new Promise((resolve, reject) => {
                  let options = {
                    offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                    limit: rowsPerPage,
                    where,
                    order,
                  };
                  listUsers({ options }).then((res) => {
                    resolve(res);
                  }).catch((error) => {
                    reject(error);
                  });
                })
            }}
            options={{
              pageSize: 20,
              flipHorizontalScroll: true,
            }}
            onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
            defaultSearchString={this.props.location?.state?.tableSearchString}
          />
        </>
      </div>
    );
  }
}

Users.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Users);
