import React, { Component } from 'react';
import { Container, Typography, TextField, Grid } from '@mui/material';
import { setError } from '../../../alerts';

import { Button } from '@lexcelon/react-util';
import { DateTime } from 'luxon';

// Api
import { batchTestDownload } from '../../../api';

// Date/Time UI
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

class TestsBatchDownload extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      startDateTime: DateTime.local().startOf('day'),
      endDateTime: DateTime.local().endOf('day'),
      startDateTimePickerOpen: false,
      endDateTimePickerOpen: false,
      practiceIdentifier: ''
    };
  }

  onStartDateChange = (newStartDate) => {
    this.setState({ startDateTime: newStartDate });
  }
  onEndDateChange = (newEndDate) => {
    this.setState({ endDateTime: newEndDate });
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { startDateTime, endDateTime, practiceIdentifier } = this.state;
    batchTestDownload({ startDateTime, endDateTime, practiceIdentifier }).then(blob => {
      this.setState({ isLoading: false });

      if (blob == null) {
        setError('Unable to download batch test images');
        return;
      }

      // Create a URL from the binary data
      const url = window.URL.createObjectURL(new Blob([blob]));

      // Create a temporary link and trigger a click event to download the file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'raw_images.zip');
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary URL and link
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }).catch(() => {
      this.setState({ isLoading: false });
      setError('Unable to download batch test images. Most likely, there are too many images to download at once.');
    });
  }

  onSetToday = () => {
    this.setState({ startDateTime: DateTime.local().startOf('day'), endDateTime: DateTime.local().endOf('day') });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Batch Test Raw Image Download</Typography>

        <form onSubmit={this.onSubmit} >
          <TextField
            required
            name='practiceIdentifier'
            label='Practice Identifier'
            value={this.state.practiceIdentifier}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            fullWidth
            style={{ marginBottom: '1em' }}
          />

          <Grid container direction='row' align-items='flex-start' spacing={2} style={{ marginBottom: '1em' }}>
            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  onOpen={() => this.setState({ startDateTimePickerOpen: true })}
                  open={this.state.startDateTimePickerOpen && !this.state.isLoading}
                  name='startDateTime'
                  label='Start Date/Time'
                  value={this.state.startDateTime}
                  onChange={this.onStartDateChange}
                  onClose={() => this.setState({ startDateTimePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  open={this.state.endDateTimePickerOpen && !this.state.isLoading}
                  onOpen={() => this.setState({ endDateTimePickerOpen: true })}
                  name='endDateTime'
                  label='End Date/Time'
                  minDate={this.state.startDateTime}
                  value={this.state.endDateTime}
                  onChange={this.onEndDateChange}
                  onClose={() => this.setState({ endDateTimePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Button type="submit" isLoading={this.state.isLoading} style={{ height: '100%', width: '100%' }} disabled={this.state.startDateTime > this.state.endDateTime}>
                Download
              </Button>
            </Grid>
          </Grid>

          <Button disabled={this.state.isLoading} onClick={this.onSetToday} style={{ width: '100px' }} secondary>Today</Button>
        </form>
      </Container>
    );
  }
}

TestsBatchDownload.propTypes = {
};

export default TestsBatchDownload;
