import React, { Component } from 'react';
import { Container, Typography, TextField, Grid } from '@mui/material';
import { setError } from '../../../alerts';

import { Button, MaterialTable } from '@lexcelon/react-util';
import { DateTime } from 'luxon';

// Api
import { listPracticesWithCounts } from '../../../api';

// Date/Time UI
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

class TestsSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      startDate: null,
      endDate: null,
      dateRangeString: 'Total Count for – to –',
      queryStartDate: null,
      queryEndDate: null,
      startDatePickerOpen: false,
      endDatePickerOpen: false
    };
  }

  onStartDateChange = (newStartDate) => {
    this.setState({ startDate: newStartDate });
  }
  onEndDateChange = (newEndDate) => {
    this.setState({ endDate: newEndDate });
  }

  onDateRangeApply = (e) => {
    e.preventDefault();
    this.setState({
      dateRangeString: 'Total count for ' + (this.state.startDate?.toLocaleString() ?? '–') + ' to ' + (this.state.endDate?.toLocaleString() ?? '–'),
      queryStartDate: this.state.startDate?.startOf('day').toISO(),
      queryEndDate: this.state.endDate?.endOf('day').toISO()
    });
  }

  render() {
    const COLUMNS = [
      {
        title: 'Practice Identifier',
        remoteField: 'identifier',
        value: rowData => rowData.getIdentifier(),
        search: 'column',
      },
      {
        title: 'Practice Name',
        remoteField: 'name',
        value: rowData => rowData.getName(),
        search: 'column',
      },
      {
        title: 'Total Count for All Time',
        value: rowData => rowData.getTestCount()
      },
      {
        title: 'Total Count for ' + DateTime.now().toFormat('MMMM yyyy'),
        value: rowData => rowData.getMonthTestCount()
      },
      {
        title: this.state.dateRangeString,
        value: rowData => rowData.getRangeTestCount() ?? ''
      }
    ];

    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Test Count Summary</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: 'red', fontWeight: 'bold', marginBottom: '1em' }}>Note: This page may be inaccurate. Please do not use for reporting or billing purposes.</Typography>

        <form onSubmit={this.onDateRangeApply} >
          <Grid container direction='row' align-items='flex-start' spacing={2} style={{ marginBottom: '1em' }}>
            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  open={this.state.startDatePickerOpen && !this.state.isLoading}
                  onOpen={() => this.setState({ startDatePickerOpen: true })}
                  name='startDate'
                  label='Start Date'
                  value={this.state.startDate}
                  onChange={this.onStartDateChange}
                  onClose={() => this.setState({ startDatePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  open={this.state.endDatePickerOpen && !this.state.isLoading}
                  onOpen={() => this.setState({ endDatePickerOpen: true })}
                  name='endDate'
                  label='End Date'
                  minDate={this.state.startDate}
                  value={this.state.endDate}
                  onChange={this.onEndDateChange}
                  onClose={() => this.setState({ endDatePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Button type="submit" isLoading={this.state.isLoading} style={{ height: '100%', width: '100%' }} disabled={this.state.startDate > this.state.endDate}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </form>

        <MaterialTable
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listPracticesWithCounts({ options }, this.state.queryStartDate, this.state.queryEndDate).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          options={{
            hideExport: true,
            flipHorizontalScroll: true,
          }}
        />

      </Container>
    );
  }
}

TestsSummary.propTypes = {
};

export default TestsSummary;
