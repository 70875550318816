import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setError, setSuccess } from '../../alerts';

// Components
import { Typography, IconButton } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listRecipes, deleteRecipe } from '../../api';

// Icons
import { Add, Delete, Edit } from '@mui/icons-material/';

import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../alerts/confirm';

// Constants
import { CONTINENTS } from '@parasightsysteminc/companion-react';

const getContinentLabel = (continent) => {
  const arr = continent.split('_');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substr(1).toLowerCase();
  }
  return arr.join(' ');
};

const getColumns = (cancelConfirm) => {
  return [
    {
      title: 'Continent',
      remoteField: 'continent',
      value: rowData => rowData.getContinent(),
      render: rowData => getContinentLabel(rowData.getContinent()),
      search: 'select',
      selectOptions: CONTINENTS.map(value => ({ value, label: getContinentLabel(value) })),
    },
    {
      title: 'ID',
      remoteField: 'id',
      value: rowData => rowData.getId(),
      search: 'column'
    },
    {
      title: 'Release Date',
      remoteField: 'releaseDate',
      value: rowData => rowData.getReleaseDate().toLocaleString()
    },
    {
      title: 'Created By',
      remoteField: 'addedByAdminUserId',
      value: rowData => rowData.getAddedByAdminUserId(),
      render: rowData => rowData.getAdminUser()?.getFirstName() + ' ' + rowData.getAdminUser()?.getLastName()
    },
    {
      title: 'Actions',
      omitFromExport: true,
      render: (rowData) => (
        <>
          <Link to={'/recipes/' + rowData.getId()} ><IconButton><Edit /></IconButton></Link>
          <IconButton onClick={() => cancelConfirm(rowData)}><Delete /></IconButton>
        </>
      )
    }
  ];
};

class Recipes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timesUpdated: 0
    };
  }

  cancelConfirm = (recipe) => {
    confirm({
      title: 'Delete Recipe',
      body: 'Are you sure you want to delete this recipe? You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deleteRecipe(recipe.getId()).then(() => {
          setSuccess('Successfully deleted recipe!');
          this.setState({ timesUpdated: this.state.timesUpdated + 1 });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to delete recipe.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  };

  render() {
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Recipes</Typography>

        <MaterialTable
          key={this.state.timesUpdated}
          title='Recipes'
          headerContent={
            <Button component={Link} to='/recipes/create'>
              Create New
              <Add style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'remote',
            columns: getColumns(this.cancelConfirm),
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listRecipes({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            flipHorizontalScroll: true,
          }}
        />
      </div>
    );
  }
}

Recipes.propTypes = {
};

export default Recipes;
