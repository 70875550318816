import React, { Component } from 'react';
import { AppBar } from '@mui/material';

// Constants
import { GREY } from '@parasightsysteminc/companion-react';

class Footer extends Component {
  render() {
    return (
      <AppBar position='absolute' style={{ position: 'absolute', height: '100px', top: 'auto', bottom: 0, backgroundColor: GREY, marginTop: '20px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      </AppBar>
    );
  }
}

export default Footer;
