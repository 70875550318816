import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import { InstrumentSettingsForm } from '../../../../components';

import { getSensorSettings, setSensorSettings } from '../../../../api';

// Values can be number or boolean
const SETTINGS = [
  {
    label: 'Fluid Level 1 Good Threshold',
    key: 'fluidLevel1GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 1 Low Threshold',
    key: 'fluidLevel1LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 2 Good Threshold',
    key: 'fluidLevel2GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 2 Low Threshold',
    key: 'fluidLevel2LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 3 Good Threshold',
    key: 'fluidLevel3GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 3 Low Threshold',
    key: 'fluidLevel3LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 4 Good Threshold',
    key: 'fluidLevel4GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 4 Low Threshold',
    key: 'fluidLevel4LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 5 Good Threshold',
    key: 'fluidLevel5GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 5 Low Threshold',
    key: 'fluidLevel5LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 6 Good Threshold',
    key: 'fluidLevel6GoodThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Level 6 Low Threshold',
    key: 'fluidLevel6LowThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 1 Present Threshold',
    key: 'fluidLine1PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 2 Present Threshold',
    key: 'fluidLine2PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 3 Present Threshold',
    key: 'fluidLine3PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 4 Present Threshold',
    key: 'fluidLine4PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 5 Present Threshold',
    key: 'fluidLine5PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line 6 Present Threshold',
    key: 'fluidLine6PresentThreshold',
    type: 'number'
  },
  {
    label: 'Fluid Line Disconnected Threshold',
    key: 'fluidLineDisconnectedThreshold',
    type: 'number'
  },
];

class InstrumentSensorSettings extends Component {

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>Instrument Sensor Settings</Typography>

        <InstrumentSettingsForm
          settings={SETTINGS}
          getSettings={getSensorSettings}
          setSettings={setSensorSettings}
          serialNumber={this.props.match?.params?.serialNumber}
        />
      </Container>
    );
  }
}

InstrumentSensorSettings.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentSensorSettings);
