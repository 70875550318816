import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Error
import { setError } from '../../alerts';

// Components
import { Container, Typography, useTheme } from '@mui/material';

import { countTestsByPractices } from '../../api';
import DropdownPicker from './DropdownPicker';
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';

const ActivePracticesGraph = ({ title, max }) => {
  // State Declarations
  // API output:
  const [testsByPractice, setTestsByPractice] = useState({});
  // Data for chart element:
  const [testCountData, setTestCountData] = useState([]);
  // The selected year:
  const [year, setYear] = useState('2023');

  const {
    palette: { primary: primaryColor },
  } = useTheme();

  // Get the data from the API whenever the year changes:
  useEffect(() => {
    const startOfYear = DateTime.fromObject({
      year: Number.parseInt(year),
    }).startOf('year');
    const endOfYear = DateTime.fromObject({
      year: Number.parseInt(year),
    }).endOf('year');
    countTestsByPractices(startOfYear, endOfYear)
      .then((tests) => {
        setTestsByPractice(tests);
      })
      .catch(setError);
  }, [year]);

  // Build the table for the chart whenever the API's output changes:
  useEffect(() => {
    let tempTestCountData = [];
    for (const practiceID in testsByPractice) {
      if (Object.hasOwnProperty.call(testsByPractice, practiceID)) {
        tempTestCountData.push({
          practiceID,
          count: testsByPractice[practiceID],
        });
      }
    }
    tempTestCountData = tempTestCountData.sort((a, b) => a.count - b.count);

    while (tempTestCountData.length > max) {
      tempTestCountData.shift();
    }

    setTestCountData(tempTestCountData);
  }, [testsByPractice]);

  return (
    <Container>
      <Typography variant="h2" style={{ marginTop: '1em' }}>
        {title}
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2em',
        }}
      >
        <ResponsiveContainer width="99%" aspect={3}>
          <BarChart data={testCountData}>
            <XAxis dataKey="practiceID" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill={primaryColor.main} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <DropdownPicker
        options={[{ value: '2023' }, { value: '2022' }]}
        onValueChange={setYear}
        label="Year"
        helperText="Year of practice data to display"
      />
    </Container>
  );
};

ActivePracticesGraph.propTypes = {
  title: PropTypes.string.isRequired,
  max: PropTypes.number,
};

export default ActivePracticesGraph;
