import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';

import { PracticeForm } from '../../../components';

class CreatePractice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToPracticeId: null
    };
  }

  render() {
    console.log(this.props.location);
    return this.state.redirectToPracticeId != null ? <Redirect to={'/practices/' + this.state.redirectToPracticeId} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Create Practice</Typography>

        <PracticeForm
          onSuccess={(practice) => this.setState({ redirectToPracticeId: practice?.getId() })}
        />
      </Container>
    );
  }
}

CreatePractice.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreatePractice);