import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import qs from 'qs';

// Date/Time UI
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

// Components
import { Autocomplete, TextField, MenuItem, Typography, Grid, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';

// Api
import { listPracticesMonthlyAccountingCounts, listTestTypes, listTypeTypeCountsForRange, listTestTypeGroups } from '../../../api';

// Icons
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

// Alerts
import { setError } from '../../../alerts';

class AccountingMonthlyCounts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: null,
      testTypes: [],
      allMonths: [],
      selectType: 'MONTH',
      startDatePickerOpen: false,
      endDatePickerOpen: false,
      startDate: DateTime.local(),
      endDate: DateTime.local(),
      filterOutSuspended: true,
      groupedTestTypesWithCounts: [],
      categories: [],
      columns: [],
    };
  }

  componentDidMount() {
    const allMonths = this.getCompletedMonthsList();
    let selectedMonth = allMonths[allMonths.length - 1];
    let parsedMonth = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.month;
    if (parsedMonth != null) {
      selectedMonth = DateTime.fromISO(parsedMonth);
    }

    Promise.all([
      listTestTypes({ options: { where: { isActive: true, isDemo: false, isComingSoon: false, isIndependentType: true } } }),
      listTestTypeGroups({ options: { where: { isActive: true, isComingSoon: false } } })
    ]).then(([{ results: testTypes }, testTypeGroups]) => {

      // Manually update the old test types for now
      testTypes.find(testType => testType.getId() === 21)?.setTestTypeGroupId(3);
      testTypes.find(testType => testType.getId() === 23)?.setTestTypeGroupId(5);

      const categories = testTypeGroups.map(testTypeGroup => ({
        id: testTypeGroup.getId(),
        type: 'GROUP',
        name: testTypeGroup.getName(),
        testTypes: testTypes.filter(testType => testType.getTestTypeGroupId() === testTypeGroup.getId())
      }));

      // Add the remaining test types
      for (let testType of testTypes) {
        if (testType.getTestTypeGroupId() == null) {
          categories.push({
            id: testType.getId(),
            type: 'TYPE',
            name: testType.getName()
          });
        }
      }

      const columns = [
        {
          title: 'Practice Identifier',
          remoteField: 'identifier',
          value: rowData => rowData.getIdentifier(),
          search: 'column'
        },
        {
          title: 'Practice Name',
          remoteField: 'name',
          value: rowData => rowData.getName(),
          search: 'column'
        },
        ...(categories.map(category => ({
          title: category.name?.replaceAll(',', ';'),
          value: rowData => {
            // If the category is a single test type, return the count for that test type
            if (category.type === 'TYPE') {
              let num = rowData.getTestTypesWithCounts()?.find(testTypeWithCount => testTypeWithCount.getId() === category.id)?.getCount() ?? 0;
              return num;
            }
  
            // If the category is a group, sum the counts for all test types in that group
            let num2 = rowData.getTestTypesWithCounts()?.filter(testTypeWithCount => category.testTypes.map(tt => tt.id).includes(testTypeWithCount.getId()))?.reduce((acc, testTypeWithCount) => acc + testTypeWithCount.getCount(), 0) ?? 0;
            return num2;
          },
          search: 'none',
          disableSort: true
        }))),
        {
          title: 'Total Billable Test Count',
          value: rowData => rowData.getTestTypesWithCounts()?.filter(ttwc => testTypes.map(tt => tt.id).includes(ttwc.id))?.reduce((acc, testTypeWithCount) => acc + testTypeWithCount.getCount(), 0) ?? 0,
          search: 'none',
          disableSort: true
        }
      ];

      this.setState({ categories, columns, allMonths, selectedMonth }, () => this.updateCounts());
    }).catch(error => {
      setError(error ?? 'Unable to load test types.');
    });
  }

  updateCounts = () => {
    const startDate = this.state.selectType === 'MONTH' ? this.state.selectedMonth?.startOf('month').toISO() : this.state.startDate?.startOf('day').toISO();
    const endDate = this.state.selectType === 'MONTH' ? this.state.selectedMonth?.endOf('month').toISO() : this.state.endDate?.endOf('day').toISO();
    if (startDate == null || endDate == null) return;
    listTypeTypeCountsForRange({ startDate, endDate, filterOutSuspended: this.state.filterOutSuspended }).then(testTypesWithCounts => {
      // Group the test types by category where applicable
      const groupedTestTypesWithCounts = [];
      for (let category of this.state.categories) {
        if (category.type === 'GROUP') {
          let count = 0;
          for (let testType of category.testTypes) {
            count += testTypesWithCounts.find(ttwc => ttwc.getId() === testType.getId())?.getCount() ?? 0;
          }
          groupedTestTypesWithCounts.push({
            name: category.name,
            count
          });
        }
        else {
          let testTypeWithCount = testTypesWithCounts.find(ttwc => ttwc.getId() === category.id);
          if (testTypeWithCount != null) {
            groupedTestTypesWithCounts.push({
              name: category.name,
              count: testTypeWithCount.getCount()
            });
          }
        }
      }

      this.setState({ groupedTestTypesWithCounts });
    }).catch(error => {
      setError(error ?? 'Unable to load test counts.');
    });
  }

  getCompletedMonthsList() {
    const start = DateTime.fromObject({ year: 2023, month: 11 });
    const now = DateTime.now();
    const end = now.minus({ months: now.day > 1 ? 0 : 1 });
    let months = [];
  
    for (let dt = start; dt <= end; dt = dt.plus({ months: 1 })) {
      months.push(dt.startOf('month'));
    }
  
    return months;
  }

  handleMonthChange = (_, selectedMonth) => {
    this.setState({ selectedMonth }, () => this.updateCounts());
  };

  onStartDateChange = (newStartDate) => {
    this.setState({ startDate: newStartDate }, () => this.updateCounts());
  };

  onEndDateChange = (newEndDate) => {
    this.setState({ endDate: newEndDate }, () => this.updateCounts());
  };

  render() {
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Monthly Practice Test Totals</Typography>

        <TextField
          select
          label='Select Type'
          value={this.state.selectType}
          onChange={(e) => this.setState({ selectType: e.target.value })}
          style={{ width: '300px', marginBottom: '1em' }}
          variant='filled'
        >
          <MenuItem value='MONTH'>Month</MenuItem>
          <MenuItem value='RANGE'>Range</MenuItem>
        </TextField>

        {this.state.selectType === 'MONTH' ? (
          <Autocomplete
            disablePortal
            options={this.state.allMonths}
            style={{ width: '300px', marginBottom: '1em' }}
            value={this.state.selectedMonth}
            getOptionLabel={(option) => option.toFormat('MMMM yyyy')}
            onChange={this.handleMonthChange}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Month'
                variant='filled'
              />
            )}
          />
        ) : (
          <Grid container direction='row' align-items='flex-start' spacing={2} style={{ marginBottom: '1em' }}>
            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  onOpen={() => this.setState({ startDatePickerOpen: true })}
                  open={this.state.startDatePickerOpen && !this.state.isLoading}
                  label='Start Date'
                  value={this.state.startDate}
                  maxDate={this.state.endDate}
                  onChange={this.onStartDateChange}
                  onClose={() => this.setState({ startDatePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  renderInput={(props) =>
                    <TextField
                      required
                      variant='filled'
                      style={{ width: '100%' }}
                      {...props}
                    />
                  }
                  open={this.state.endDatePickerOpen && !this.state.isLoading}
                  onOpen={() => this.setState({ endDatePickerOpen: true })}
                  label='End Date'
                  minDate={this.state.startDate}
                  value={this.state.endDate}
                  onChange={this.onEndDateChange}
                  onClose={() => this.setState({ endDatePickerOpen: false })}
                  disabled={this.state.isLoading}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}

        <FormGroup>
          <FormControlLabel control={<Switch checked={this.state.filterOutSuspended} onChange={e => this.setState({ filterOutSuspended: e.target.checked }, () => this.updateCounts())} />} label='Filter out suspended practices' />
        </FormGroup>
        {this.state.filterOutSuspended && <Typography variant='body2' style={{ color: 'red' }}>Suspended practices may have billable tests for the selected time frame.</Typography>}

        <MaterialTable
          title='Test Counts'
          options={{
            pageSize: 100,
            flipHorizontalScroll: true,
          }}
          data={{
            mode: 'remote',
            columns: [...this.state.columns, ...(this.state.selectType === 'MONTH' ? [{
              title: 'Logs',
              omitFromExport: true,
              render: (rowData) => <Link to={{
                pathname: `/practices/${rowData.getId()}/monthly-tests`,
                search: `?month=${this.state.selectedMonth?.toISO()}`,
                state: { backTo: { pathname: { pathname: this.props.location.pathname, search: `?month=${this.state.selectedMonth?.toISO()}` }, description: 'Monthly Practice Test Totals' } }
              }}><HistoryEduIcon /></Link>,
            }] : [])],
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                // Check if startDate or endDate are invalid DateTime objects
                if (this.state.selectType === 'RANGE' && (this.state.startDate == null || this.state.endDate == null || !this.state.startDate.isValid || !this.state.endDate.isValid)) reject('Invalid dates');

                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where: {
                    ...where,
                    timestamp: {
                      ['$between']: this.state.selectType === 'MONTH' ? [this.state.selectedMonth.startOf('month').toISO(), this.state.selectedMonth.endOf('month').toISO()] : [this.state.startDate.startOf('day').toISO(), this.state.endDate.endOf('day').toISO()]
                    },
                    ...(this.state.filterOutSuspended ? { isSuspended: false } : {})
                  },
                  order,
                };
                listPracticesMonthlyAccountingCounts({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
        />

        <MaterialTable
          title='Total Test Type Counts'
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          data={{
            mode: 'list',
            columns: [
              {
                title: 'Test Type',
                value: rowData => rowData.name,
              },
              {
                title: 'Count',
                value: rowData => rowData.count,
              }
            ],
            rows: this.state.groupedTestTypesWithCounts
          }}
        />
      </div>
    );
  }
}

AccountingMonthlyCounts.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(AccountingMonthlyCounts);
