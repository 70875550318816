import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import { BackToButton, MaterialTable } from '@lexcelon/react-util';

import { listErrorLogs } from '../../../../api';


import { Typography } from '@mui/material';

const COLUMNS = [
  {
    value: rowData => rowData.getReportedTimestamp().toISOString(),
    title: 'Reported At'
  },
  {
    value: rowData => rowData.getErrorCode(),
    title: 'Error Code'
  },
  {
    value: rowData => rowData.getTestId(),
    title: 'Test ID'
  },
  {
    value: rowData => rowData.getAction(),
    title: 'Action'
  },
  {
    value: rowData => rowData.getInstrumentSerialNumber(),
    title: 'Instrument'
  },
  {
    value: rowData => rowData.getUserFullName(),
    title: 'User'
  },
  {
    value: rowData => rowData.getAdditionalInfo(),
    title: 'Additional Info'
  }
];

class PracticeErrorLog extends Component {
  constructor(props) {
    super(props);
    this.practiceId = this.props.match?.params?.id;
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Error Log</Typography>
        <MaterialTable
          title={''}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>
              new Promise((resolve, reject) => {
                where.practiceId = this.practiceId;
                const options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listErrorLogs({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
        />
      </div>
    );
  }
}

PracticeErrorLog.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(PracticeErrorLog);