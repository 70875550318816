import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import { InstrumentSettingsForm } from '../../../../components';

import { getPumpSettings, setPumpSettings } from '../../../../api';

// Values can be number or boolean
const SETTINGS = [
  {
    label: 'Acceleration',
    key: 'acceleration',
    type: 'number'
  },
  {
    label: 'Drop Detect Enter Threshold',
    key: 'dropDetectEnterThreshold',
    type: 'number'
  },
  {
    label: 'Drop Detect Exit Threshold',
    key: 'dropDetectExitThreshold',
    type: 'number'
  },
  {
    label: 'Drop Detect Sense Sample Rate',
    key: 'dropDetectSenseSampleRate',
    type: 'number'
  },
  {
    label: 'Max Drop Steps',
    key: 'maxDropSteps',
    type: 'number'
  },
  {
    label: 'Priming Steps',
    key: 'primingSteps',
    type: 'number'
  },
  {
    label: 'Suckback Every Drop',
    key: 'suckbackEveryDrop',
    type: 'boolean'
  },
  {
    label: 'Suckback Rate',
    key: 'suckbackRate',
    type: 'number'
  },
  {
    label: 'Suckback Steps',
    key: 'suckbackSteps',
    type: 'number'
  }
];

class InstrumentPumpSettings extends Component {

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>Instrument Pump Settings</Typography>

        <InstrumentSettingsForm
          settings={SETTINGS}
          getSettings={getPumpSettings}
          setSettings={setPumpSettings}
          serialNumber={this.props.match?.params?.serialNumber}
        />
      </Container>
    );
  }
}

InstrumentPumpSettings.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentPumpSettings);
