import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography, TextField } from '@mui/material';
import { BackToButton, Button } from '@lexcelon/react-util';

// APIs
import { inviteAdminUser } from '../../../api';

import { setError, setSuccess } from '../../../alerts';

class InviteAdminUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isLoading: false,
      redirect: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      email,
    } = this.state;

    inviteAdminUser({ email }).then(() => {
      setSuccess('Successfully invited admin user!');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to invite admin user. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to='/admin-users/' />
    ) : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Invite Admin User</Typography>

        <form onSubmit={this.onSubmit}>
          {/* Email */}
          <TextField
            required
            name='email'
            label='Email'
            type='email'
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            fullWidth
            sx={{ mb: '.5em' }}
          />

          {/* Submit Button */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
            <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
              Submit
            </Button>
          </div>

        </form>
      </Container>
    );
  }
}

InviteAdminUser.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(InviteAdminUser);