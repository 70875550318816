import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';

class Triggers extends Component {
  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Triggers</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginTop: '1em' }}>Coming Soon</Typography>
      </Container>
    );
  }
}

Triggers.propTypes = {
};

export default Triggers;
