import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { BackToButton, Button } from '@lexcelon/react-util';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { generatePassword } from '../../../../util';

// APIs
import { createUser } from '../../../../api';

import { setError, setSuccess } from '../../../../alerts';

class CreateUser extends Component {

  constructor(props) {
    super(props);

    this.practiceId = this.props.match?.params?.id;
    this.practice = null;

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: generatePassword(),
      isLoading: false,
      redirect: false,
      showPassword: true,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      firstName,
      lastName,
      email,
      password
    } = this.state;

    createUser({ email, password, firstName, lastName, practiceId: this.practiceId }).then(() => {
      setSuccess('Successfully created user!');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to create user. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.props.location?.state?.backTo?.pathname} />
    ) : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Create User for Practice {this.practice?.getName()}</Typography>

        <form onSubmit={this.onSubmit}>
          {/* First and Last Name */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '0.5em' }}>
            <TextField
              required
              name='firstName'
              label='First Name'
              value={this.state.firstName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '49%' }}
            />

            <TextField
              required
              name='lastName'
              label='Last Name'
              value={this.state.lastName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '49%' }}
            />
          </div>

          {/* Email */}
          <TextField
            required
            name='email'
            label='Email'
            type='email'
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            fullWidth
            sx={{ mb: '.5em' }}
          />

          {/* Password */}
          <TextField
            required
            name='password'
            label='Password'
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            fullWidth
            helperText='Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{ marginBottom: '0.5em' }}
          />

          {/* Submit Button */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
            <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
              Submit
            </Button>
          </div>

        </form>
      </Container>
    );
  }
}

CreateUser.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(CreateUser);
