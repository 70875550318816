import React, { Component } from 'react';
import { Container, Typography, TextField } from '@mui/material';
import { Button, LineItem } from '@lexcelon/react-util';

// API
import { getPlatformSettings, updatePlatformSetting } from '../../api';

// Alerts
import { setError, setSuccess } from '../../alerts';
import { closeConfirm, confirm, startConfirmLoading, stopConfirmLoading } from '../../alerts/confirm';

class PlatformSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      platformSettings: null,
      message: '',
      isLoading: false
    };
  }

  componentDidMount() {
    this.retrievePlatformSettings();
  }

  retrievePlatformSettings = () => {
    getPlatformSettings().then(platformSettings => {
      this.setState({ platformSettings });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve platform settings. Please try again.');
    });
  }

  onSetInfoBanner = (clear) => {
    this.setState({ isLoading: true });
    updatePlatformSetting('INFO_BANNER', clear ? '' : this.state.message).then(() => {
      setSuccess(`Info banner ${clear ? 'clearaed' : 'set'} successfully.`);
      this.retrievePlatformSettings();
      this.setState({ message: '' });
    }).catch(error => {
      setError(error ?? `Error: Unable to ${clear ? 'clear' : 'set'} info banner. Please try again.`);
    }).finally(() => this.setState({ isLoading: false }));
  }

  openConstructionDialog = (newUnderConstructionFlag) => {
    confirm({
      title: `WARNING: Turn ${newUnderConstructionFlag === 'true' ? 'On' : 'Off'} Under Construction Flag`,
      body: newUnderConstructionFlag ? 'Are you sure you want to turn on the under construction flag? This will prevent any tests from starting.' : 'Are you sure you want to turn off the under construction flag? This will allow tests to start.',
      onConfirm: () => {
        this.setState({ isLoading: true });
        startConfirmLoading();
        updatePlatformSetting('UNDER_CONSTRUCTION', newUnderConstructionFlag).then(() => {
          setSuccess(`Under construction flag turned ${newUnderConstructionFlag === 'true' ? 'on' : 'off'} successfully.`);
          this.retrievePlatformSettings();
        }).catch(error => {
          setError(error ?? `Error: Unable to turn ${newUnderConstructionFlag === 'true' ? 'on' : 'off'} under construction flag. Please try again.`);
        }).finally(() => {
          this.setState({ isLoading: false });
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  render() {
    const infoBanner = this.state.platformSettings?.find(setting => setting.getKey() === 'INFO_BANNER')?.getValue();
    const underConstruction = this.state.platformSettings?.find(setting => setting.getKey() === 'UNDER_CONSTRUCTION')?.value;
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Platform Settings</Typography>

        <Typography variant='h2'>Info Banner</Typography>
        <Typography variant='body1'>This is the info banner that will be displayed on the AIOs.</Typography>
        <LineItem
          value={infoBanner}
          description='Value'
        />
        {infoBanner != null &&
        <>
          <TextField
            required
            label='Message'
            value={this.state.message}
            onChange={e => this.setState({ message: e.target.value })}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%' }}
          />
          <Button danger onClick={() => this.onSetInfoBanner(false)} style={{ width: '100%', marginTop: 5 }} disabled={this.state.message === ''} isLoading={this.state.isLoading}>Set Info Banner</Button>
        </>}

        {infoBanner !== '' && infoBanner != null &&
        <Button danger onClick={() => this.onSetInfoBanner(true)} style={{ width: '100%', marginTop: 20 }} disabled={this.state.isLoading}>Clear Info Banner</Button>}

        {/* Under Construction Flag */}
        <Typography variant='h2' style={{ marginTop: '2em' }}>Under Construction Flag</Typography>
        <Typography variant='body1'>If this flag is set, no tests can begin. A banner alerting customers will also appear across the top of the AIO.</Typography>
        <LineItem
          value={underConstruction}
          description='Value'
        />
        {(underConstruction === 'true' || underConstruction === 'false') &&
        <Button danger onClick={() => this.openConstructionDialog(underConstruction === 'true' ? 'false' : 'true')} style={{ width: '100%', marginTop: 5 }} disabled={this.state.isLoading}>Turn {underConstruction === 'true' ? 'Off' : 'On'} Under Construction Flag</Button>}
      </Container>
    );
  }
}

export default PlatformSettings;