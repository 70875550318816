import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Header } from '@lexcelon/react-util';

// Redux
import { connect } from 'react-redux';
import { checkAuthentication, logoutUser } from '../redux/actions/authActions';

// Constants
import { SmallLogo, GREY } from '@parasightsysteminc/companion-react';
const LOGGED_IN_LINKS = [
  {
    slug: '/tests',
    text: 'Tests',
    subLinks: [
      {
        slug: '/',
        text: 'All Tests'
      },
      {
        slug: '/summary',
        text: 'Summary'
      },
      {
        slug: '/reporting',
        text: 'Reporting'
      },
      {
        slug: '/types',
        text: 'Types'
      },
      {
        slug: '/batch-download',
        text: 'Batch Download'
      }
    ]
  },
  {
    slug: '/practices',
    text: 'Practices',
    subLinks: [
      {
        slug: '/',
        text: 'All Practices'
      },
      {
        slug: '/reporting',
        text: 'Reporting'
      },
      {
        slug: '/create',
        text: 'Create New Practice'
      }
    ]
  },
  {
    slug: '/instruments',
    text: 'Instruments'
  },
  {
    slug: '/users',
    text: 'Users'
  },
  {
    slug: '/admin-users',
    text: 'Admin Users'
  },
  {
    slug: '/accounting/monthly-counts',
    text: 'Accounting',
  },
  {
    slug: '/inventory',
    text: 'Inventory',
  },
  {
    slug: '/errors',
    text: 'Errors',
    subLinks: [
      {
        slug: '/known',
        text: 'Known Errors',
      },
      {
        slug: '/log',
        text: 'Error Log',
      },
    ],
  },
  {
    slug: '/platform-settings',
    text: 'Platform Settings'
  },
  {
    slug: '/account',
    text: 'My Account'
  }
];

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Login'
  }
];

class AppBar extends Component {
  componentDidMount() {
    this.props.checkAuthentication(this.props.isAuthenticated);
  }

  render() {
    return (
      <Header
        logo={SmallLogo}
        logoDescription='Parasight Logo'
        links={this.props.isAuthenticated ? LOGGED_IN_LINKS : LOGGED_OUT_LINKS}
        backgroundColor={'lightgrey'}
        textColor={GREY}
        drawer={this.props.isAuthenticated}
        isLoading={this.props.isLoading}
        onLogout={this.props.isAuthenticated ? this.props.logoutUser : null}
        title='Admin'
        pathname={location.pathname}
      />
    );
  }
}

AppBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated,
  isLoading: state?.auth?.isLoading
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (isAuthenticated) => checkAuthentication(dispatch, isAuthenticated),
  logoutUser: () => logoutUser(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
