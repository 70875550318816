import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';

/**
 * A generic dropdown picker. The props `options` (array of labels and values in js objects)
 * and `onChange` (passed updated value) are required. Defaults to `initialValue ?? options[0] ?? null`.
 *
 * Optional props are:
 *
 */
export const DropdownPicker = ({
  options,
  initialValue,
  label,
  helperText,
  onValueChange,
  textFieldProps,
  menuItemProps,
}) => {
  const [value, setValue] = useState(initialValue ?? options[0]?.value ?? null);

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  return (
    <div>
      <TextField
        select
        label={label}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        helperText={helperText}
        variant="filled"
        {...textFieldProps}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value} {...menuItemProps}>
            {option.label ?? option.value.toString()}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

DropdownPicker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  helperText: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  textFieldProps: PropTypes.object,
  menuItemProps: PropTypes.object,
};

export default DropdownPicker;
