import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, MenuItem, TextField, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Link, withRouter } from 'react-router-dom';
import { Button } from '@lexcelon/react-util';
import qs from 'qs';

// APIs
import { createRecipe, listTestTypes } from '../../api';

import { setSuccess, setError } from '../../alerts';

import { CONTINENTS } from '@parasightsysteminc/companion-react';

const getContinentLabel = (continent) => {
  const arr = continent.split('_');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substr(1).toLowerCase();
  }
  return arr.join(' ');
};

const checkBoxIcon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkBoxCheckedIcon = <CheckBoxIcon fontSize='small' />;

class RecipeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      releaseDate: null,
      continents: [],
      selectAllContinents: false,
      isLoading: false,
      testTypes: [],
      testType: null,
      order: 0
    };
  }

  componentDidMount() {
    let parsedTestTypeId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.testTypeId;
    this.testTypeId = !isNaN(parsedTestTypeId) ? parseInt(parsedTestTypeId) : null;
    this.order = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.order;
    this.continent = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.continent;

    if (this.continent != null) this.setState({ continents: [this.continent] });
    if (this.order != null) this.setState({ order: this.order });

    listTestTypes().then(({ results: testTypes }) => {
      this.setState({ testTypes });

      if (this.testTypeId != null) this.setState({ testType: testTypes.find(testType => testType.getId() === this.testTypeId) ?? null });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test types.');
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      order,
      continents,
      scriptContent,
      testType
    } = this.state;

    createRecipe({ testTypeId: testType.id, order, continents, scriptContent }).then(recipe => {
      setSuccess('Successfully created recipe!');
      this.setState({ isLoading: false });
      this.props.onSuccess(recipe);
    }).catch(error => {
      setError(error ?? 'Error: Unable to create recipe. Please try again.');
      this.setState({ isLoading: false });
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onReleaseDateChange = (newReleaseDate) => {
    this.setState({ releaseDate: newReleaseDate });
  }

  onContinentChange = (_, newContinents) => {
    this.setState({ continents: newContinents, selectAllContinents: newContinents.length == CONTINENTS.length });
  }

  onSelectAllChange = (_, newSelectAll) => {
    this.setState({ selectAllContinents: newSelectAll, continents: newSelectAll? CONTINENTS: [] });
  };

  onTestTypeChange = (_, newTestType) => {
    this.setState({ testType: newTestType });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>

        {/* Test Type */}
        <Autocomplete
          disablePortal
          name="testType"
          options={this.state.testTypes}
          getOptionLabel={(option) => option.getName()}
          style={{ width: '100%', marginBottom: '0.5em' }}
          disabled={this.state.isLoading || this.testTypeId != null}
          value={this.state.testType}
          onChange={this.onTestTypeChange}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label='Test Type'
              variant='filled'
              disabled={this.state.isLoading || this.testTypeId != null}
            />
          )}
        />

        {/* Order */}
        {this.state.testType?.getHasIncubation() &&
        <TextField
          label='Order'
          name='order'
          select
          onChange={this.onChange}
          value={this.state.order}
          style={{ width: '100%', marginBottom: '0.5em' }}
          variant='filled'
          disabled={this.state.isLoading}
          required
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
        </TextField>}

        {/* Continent Selection - Select One */}
        {/* <Autocomplete
          disablePortal
          name="continent"
          options={CONTINENTS}
          getOptionLabel={(option) => getContinentLabel(option)}
          style={{ width: '100%', marginBottom: '0.5em' }}
          disabled={this.state.isLoading}
          value={this.state.continent}
          onChange={this.onContinentChange}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label='Continent'
              variant='filled'
              disabled={this.state.isLoading}
            />
          )}
        /> */}

        <Autocomplete
          multiple
          name="continent"
          id="checkboxes-continent"
          options={CONTINENTS}
          disableCloseOnSelect
          getOptionLabel={(option) => getContinentLabel(option)}
          style={{ width: '100%', marginBottom: '0.5em' }}
          disabled={this.state.isLoading}
          onChange={this.onContinentChange}
          value={this.state.continents}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={checkBoxIcon}
                checkedIcon={checkBoxCheckedIcon}
                style={{ marginRight: 8 }}
                checked={selected || this.state.continents.includes(option)}
              />
              {getContinentLabel(option)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Continents'
              variant= 'filled'
              disabled={this.state.isLoading}
            />
          )}
        />

        <FormControlLabel
          value="top"
          control={
            <Checkbox
              icon={checkBoxIcon}
              checkedIcon={checkBoxCheckedIcon}
              style={{ marginRight: 8 }}
              checked={this.state.selectAllContinents}
              onChange={this.onSelectAllChange}
            />
          }
          label='All Continents'
          labelPlacement='end'
        />

        {/* Recipe script */}
        <TextField
          name='scriptContent'
          label='Recipe Script'
          fullWidth
          multiline
          rows={15}
          style={{ marginTop: '0.5em' }}
          value={this.state.scriptContent}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          required />

        {/* Form Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '1em' }}>
          {this.props.onClose != null &&
            <Link to='/recipes' style={{ width: '48%' }} >
              <Button secondary disabled={this.state.isLoading} style={{ width: '100%' }}>
                Cancel
              </Button>
            </Link>}

          {/* Submit Button */}
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '48%' }}>
            Submit
          </Button>
        </div>
      </form >
    );
  }
}

RecipeForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  location: PropTypes.object
};

export default withRouter(RecipeForm);
