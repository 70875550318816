import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { JsonPreview } from '../../../components';

// API
import { getInstrument, getSystemInfo, deactivateInstrument, toggleIgnoreCartridgeSensors, toggleIgnoreCartridgeReadySensors } from '../../../api';

// Components
import { CircularProgress, Container, Typography } from '@mui/material';
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';

// Alerts
import { setError, setSuccess, clearErrors } from '../../../alerts';
import { startConfirmLoading, confirm, closeConfirm } from '../../../alerts/confirm';

class Instrument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingInstrument: false,
      isLoadingSystemInfo: false,
      instrument: null,
      systemInfo: null,
      friendlyName: '',
      isLoadingIgnoreCartridgeReadySensors: false,
      isLoadingIgnoreCartridgeSensors: false,
    };

    this.hasMountedAlready = false;
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      this.retrieveInstrument();
      this.retrieveSystemInfo();
    }
    this.hasMountedAlready = true;
  }

  componentWillUnmount() {
    clearErrors();
  }

  retrieveInstrument = () => {
    // Retrieve the instrument id from the url
    const instrumentSerialNumber = this.props.match?.params?.serialNumber;

    this.setState({ isLoadingInstrument: true });
    getInstrument(instrumentSerialNumber).then(instrument => {
      this.setState({ isLoadingInstrument: false, instrument });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve instrument info. Please try again.');
      this.setState({ isLoadingInstrument: false });
    });
  }

  retrieveSystemInfo = () => {
    this.setState({ isLoadingSystemInfo: true });
    getSystemInfo(this.props.match?.params?.serialNumber)
      .then((systemInfo) => {
        this.setState({ systemInfo, isLoadingSystemInfo: false });
      })
      .catch((error) => {
        setError(error?.error ?? 'Could not retrieve system info.');
        this.setState({ isLoadingSystemInfo: false });
      });
  };

  deactivateInstrument = () => {
    confirm({
      title: 'WARNING: Do you want to deactivate this instrument?',
      body: 'If you deactivate this instrument, it will not be able to run any tests until it is reactivated by a new practice. Would you like to continue?',
      onConfirm: () => {
        startConfirmLoading();
        deactivateInstrument(this.props.match?.params?.serialNumber).then(() => {
          closeConfirm();
          setSuccess('Instrument successfully deactivated.');
          this.retrieveInstrument();
        }).catch(error => {
          setError(error?.error ?? 'Error: Unable to deactivate instrument. Please try again.');
          closeConfirm();
        });
      },
      danger: true
    });
  }

  toggleIgnoreCartridgeSensors = () => {
    this.setState({ isLoadingIgnoreCartridgeSensors: true });
    toggleIgnoreCartridgeSensors(this.props.match?.params?.serialNumber, { ignoreCartridgeSensors: !this.state.instrument?.getIgnoreCartridgeSensors() }).then(instrument => {
      this.setState({ isLoadingIgnoreCartridgeSensors: false, instrument });
      setSuccess(`Successfully turned ${instrument.getIgnoreCartridgeSensors() ? 'on' : 'off'} ignore cartridge sensors flag`);
    }).catch(error => {
      setError(error?.error ?? 'Error: Unable to toggle ignore cartridge sensors. Please try again.');
      this.setState({ isLoadingIgnoreCartridgeSensors: false });
    });
  }

  toggleIgnoreCartridgeReadySensors = () => {
    this.setState({ isLoadingIgnoreCartridgeReadySensors: true });
    toggleIgnoreCartridgeReadySensors(this.props.match?.params?.serialNumber, { ignoreCartridgeReadySensors: !this.state.instrument?.getIgnoreCartridgeReadySensors() }).then(instrument => {
      this.setState({ isLoadingIgnoreCartridgeReadySensors: false, instrument });
      setSuccess(`Successfully turned ${instrument.getIgnoreCartridgeReadySensors() ? 'on' : 'off'} ignore cartridge ready sensors flag`);
    }).catch(error => {
      setError(error?.error ?? 'Error: Unable to toggle ignore cartridge ready sensors. Please try again.');
      this.setState({ isLoadingIgnoreCartridgeReadySensors: false });
    });
  }

  render() {
    const locationState = { backTo: { pathname: this.props.location.pathname, description: `Instrument ${this.state.instrument?.getSerialNumber() ?? ''}` } };
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        {this.state.instrument != null &&
        <>
          <Typography
            variant='h1'
            style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
            Instrument{this.state.instrument != null ? ': ' + this.state.instrument.getSerialNumber() : ''}
          </Typography>

          <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Instrument Information</Typography>

          {this.state.isLoadingInstrument ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <LineItem
                value={this.state.instrument.getSerialNumber()}
                description='Serial Number'
              />

              <LineItem
                value={this.state.instrument.getFriendlyName() || '(None)'}
                description='Display Name'
              />

              <LineItem
                value={this.state.instrument.getPracticeId() != null ? (
                  <Link to={{ pathname: `/practices/${this.state.instrument.getPracticeId()}`, state: locationState }}>{this.state.instrument.getPractice()?.getName() ?? 'Practice'}</Link>
                ) : '(None)'}
                description='Practice'
              />

              <LineItem
                value={this.state.instrument.getIsBusy() ? 'Busy' : 'Free'}
                description='Is Busy'
              />

              <LineItem
                value={this.state.instrument.getIsSuspended() ? 'Suspended' : 'Active'}
                description='Is Suspended'
              />

              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine1()}
                description='Tests Since Last Change on Line 1'
              />
              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine2()}
                description='Tests Since Last Change on Line 2'
              />
              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine3()}
                description='Tests Since Last Change on Line 3'
              />
              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine4()}
                description='Tests Since Last Change on Line 4'
              />
              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine5()}
                description='Tests Since Last Change on Line 5'
              />
              <LineItem
                value={this.state.instrument.getTestsSinceLastChangeLine6()}
                description='Tests Since Last Change on Line 6'
              />

              <Button onClick={this.deactivateInstrument} danger>
                Deactivate Instrument
              </Button>
            </div>
          )}

          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}>System Info</Typography>

          {this.state.isLoadingSystemInfo ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/logs`, state: locationState }}>
                View Logs
              </Button>

              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/sensors`, state: locationState }} style={{ marginLeft: 10 }}>
                View Sensors
              </Button>

              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/pump-settings`, state: locationState }} style={{ marginLeft: 10 }}>
                Pump Settings
              </Button>

              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/sensor-settings`, state: locationState }} style={{ marginLeft: 10 }}>
                Sensor Settings
              </Button>

              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/shuttle-settings`, state: locationState }} style={{ marginLeft: 10 }}>
                Shuttle Settings
              </Button>

              <Button component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/camera-params`, state: locationState }} style={{ marginLeft: 10 }}>
                Camera Params
              </Button>

              {this.state.systemInfo != null &&
              <div style={{ marginTop: '1em' }}>
                <JsonPreview jsonObj={this.state.systemInfo} />
              </div>}
            </div>
          )}

          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}>Sensor Flags</Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='body1'><b>Ignore Cartridge Sensors Flag:</b> <b style={{ color: this.state.instrument?.getIgnoreCartridgeSensors() ? 'red' : 'green' }}>{this.state.instrument?.getIgnoreCartridgeSensors() ? 'ON' : 'OFF'}</b></Typography>
            <Button danger onClick={this.toggleIgnoreCartridgeSensors} isLoading={this.state.isLoadingIgnoreCartridgeSensors} disabled={this.state.isLoadingIgnoreCartridgeReadySensors} style={{ width: '340px', marginTop: '5px' }}>Turn {this.state.instrument?.getIgnoreCartridgeSensors() ? 'Off' : 'On'} Ignore Cartridge Sensors Flag</Button>

            <Typography variant='body1' style={{ marginTop: '30px' }}><b>Ignore Cartridge Ready Sensors Flag:</b> <b style={{ color: this.state.instrument?.getIgnoreCartridgeReadySensors() ? 'red' : 'green' }}>{this.state.instrument?.getIgnoreCartridgeReadySensors() ? 'ON' : 'OFF'}</b></Typography>
            <Button danger onClick={this.toggleIgnoreCartridgeReadySensors} isLoading={this.state.isLoadingIgnoreCartridgeReadySensors} disabled={this.state.isLoadingIgnoreCartridgeSensors} style={{ marginTop: '5px', width: '340px' }}>Turn {this.state.instrument?.getIgnoreCartridgeReadySensors() ? 'Off' : 'On'} Ignore Cartridge Ready Sensors Flag</Button>
          </div>
        </>}
      </Container>
    );
  }
}

Instrument.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Instrument);
