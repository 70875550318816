import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setError } from '../../../alerts';

// Components
import { Typography } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';

// Api
import { listTestTypes } from '../../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// Constants
const COLUMNS = [
  {
    title: 'Name',
    value: rowData => rowData.getName(),
    search: 'column',
    customSort: (a, b) => a?.getName()?.localeCompare(b?.getName() ?? '') ?? 0
  },
  {
    title: 'Animal Species',
    value: rowData => rowData.getParentTestType() == null ? rowData.getAnimalSpecies()?.map(animalSpecies => animalSpecies.getName()).join(', ') : rowData.getParentTestType()?.getAnimalSpecies()?.map(animalSpecies => animalSpecies.getName()).join(', '),
  },
  {
    title: 'Coming Soon',
    value: rowData => rowData.getIsComingSoon() ? 'Coming Soon' : 'Functional',
    search: 'select',
    selectOptions: [
      { value: 'Coming Soon', label: 'Coming Soon' },
      { value: 'Functional', label: 'Functional' }
    ]
  },
  {
    title: 'Is Training',
    value: rowData => rowData.getIsDemo() ? 'Training' : 'Normal',
    search: 'select',
    customSort: (a, b) => {
      let aVal = a?.getIsDemo() ? 'Training' : 'Normal';
      let bVal = b?.getIsDemo() ? 'Training' : 'Normal';
      return aVal.localeCompare(bVal);
    }
  },
  {
    title: 'View',
    value: () => '',
    omitFromExport: true,
    render: (rowData) => (
      <Link to={{ pathname: `/tests/types/${rowData.getId()}`, state: { backTo: { pathname: '/tests/types', description: 'All Test Types' } } }}><VisibilityIcon /></Link>
    )
  }
];

class TestTypes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      testTypes: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listTestTypes().then(({ results }) => {
      this.setState({ isLoading: false, testTypes: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test types. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Test Types</Typography>

        <MaterialTable
          title='Test Types'
          data={{
            mode: 'list',
            rows: this.state.testTypes?.filter(testType => testType.getIsIndependentType() && testType.getIsActive()),
            columns: COLUMNS
          }}
          isLoading={this.state.isLoading}
          options={{
            hideExport: true,
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          defaultSearchString={'{"table":"","columns":{"Is Training":{"searchType":"select","searchValue":["Normal"]},"Coming Soon":{"searchType":"select","searchValue":["Functional"]}}}'}
        />
      </div>
    );
  }
}

TestTypes.propTypes = {
};

export default TestTypes;
