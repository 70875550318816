import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Typography, IconButton } from '@mui/material';
import { BackToButton, MaterialTable } from '@lexcelon/react-util';
import { listPracticeInventoryTypes, getPractice } from '../../../../api';
import { setError } from '../../../../alerts';
import EditIcon from '@mui/icons-material/Edit';
import { EditInventoryTypeDialog } from '../../../../components';

const COLUMNS = ({ onEdit }) => ([
  {
    title: 'Item',
    remoteField: 'inventoryTypeId',
    value: rowData => rowData.getInventoryType()?.getName() ?? null
  },
  {
    title: 'Count',
    remoteField: 'count',
    value: rowData => rowData.getCount() ?? null
  },
  {
    title: 'Order Quantity',
    remoteField: 'orderQuantity',
    value: rowData => rowData.getOrderQuantity() ?? null
  },
  {
    title: 'Order Trigger Number',
    remoteField: 'orderTriggerNumber',
    value: rowData => rowData.getOrderTriggerNumber() ?? null
  },
  {
    title: 'Edit',
    value: () => '',
    render: (rowData) => (
      <IconButton onClick={() => onEdit(rowData)}><EditIcon /></IconButton>
    )
  },
]);

class PracticeInventory extends Component {
  constructor(props) {
    super(props);
    this.practiceId = this.props.match?.params?.id;

    this.state = {
      practice: null,
      editDialogInventoryType: null
    };
  }

  componentDidMount() {
    getPractice(this.practiceId).then(practice => this.setState({ practice })).catch(error => {
      setError(error ?? 'Error: Unable to retrieve practice info.');
    });
  }

  onEdit = (inventoryType) => {
    this.setState({ editDialogInventoryType: inventoryType });
  }

  onCloseEditDialog = () => {
    this.setState({ editDialogInventoryType: null });
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.practice?.getName() ?? 'Practice'} Inventory</Typography>

        <MaterialTable
          title='Inventory'
          data={{
            mode: 'remote',
            columns: COLUMNS({ onEdit: this.onEdit }),
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listPracticeInventoryTypes(this.practiceId, { options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          onError={(error) => {
            setError(error);
          }}
        />

        <EditInventoryTypeDialog isOpen={this.state.editDialogInventoryType != null} onClose={this.onCloseEditDialog} practiceInventoryType={this.state.editDialogInventoryType} />
      </Container>
    );
  }
}

PracticeInventory.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(PracticeInventory);