export function generatePassword(length = 10) {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_+-=[]{}|;:\'",.<>?/';
  const allCharacters = uppercase + lowercase + numbers + symbols;

  // Ensure the password meets the minimum requirements
  const passwordArray = [
    uppercase[Math.floor(Math.random() * uppercase.length)],
    lowercase[Math.floor(Math.random() * lowercase.length)],
    numbers[Math.floor(Math.random() * numbers.length)],
    symbols[Math.floor(Math.random() * symbols.length)]
  ];

  // Fill the rest of the password length with random characters from all allowed types
  for (let i = 4; i < length; i++) {
    passwordArray.push(allCharacters[Math.floor(Math.random() * allCharacters.length)]);
  }

  // Shuffle the array to ensure the order of character types is random
  const shuffledPasswordArray = passwordArray.sort(() => 0.5 - Math.random());

  // Join the array into a string to form the final password
  return shuffledPasswordArray.join('');
}