import { combineReducers } from 'redux';

// Reducers
import alertReducer from './alertReducer';
import confirmReducer from './confirmReducer';
import authReducer from './authReducer';

export default combineReducers({
  alerts: alertReducer,
  confirm: confirmReducer,
  auth: authReducer
});
