import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Error
import { setError } from '../../alerts';

// Components
import { Container, Typography, useTheme } from '@mui/material';

import { countTestsByMonth } from '../../api';
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import { MONTH_NAMES } from '@parasightsysteminc/companion-react';

const YearOverYearTestsGraph = ({ title }) => {
  // State Declarations
  // API output:
  const [testsByMonth, setTestsByMonth] = useState({});
  // Data for chart element:
  const [testCountData, setTestCountData] = useState([]);
  // All years covered by the chart:
  const [years, setYears] = useState([]);

  const {
    palette: { primary: primaryColor },
  } = useTheme();

  // Get the data from the API whenever the year changes:
  useEffect(() => {
    countTestsByMonth()
      .then((tests) => {
        setTestsByMonth(tests);
      })
      .catch(setError);
  }, []);

  // Build the table for the chart whenever the API's output changes:
  useEffect(() => {
    let tempTestCountData = [];
    let tempYears = [];

    for (const yearAndMonth in testsByMonth) {
      if (Object.hasOwnProperty.call(testsByMonth, yearAndMonth)) {
        const [year, month] = yearAndMonth.split('-');
        if (!tempYears.includes(year)) {
          tempYears.push(year);
        }

        const monthNum = parseInt(month, 10);
        if (!Number.isNaN(monthNum)) {
          const monthString = MONTH_NAMES[monthNum - 1];

          const existingEntryIdx = tempTestCountData.findIndex(
            (item) => item.month === monthString
          );
          if (existingEntryIdx === -1) {
            tempTestCountData.push({
              month: monthString,
              [year]: testsByMonth[yearAndMonth],
            });
          }
          else {
            tempTestCountData[existingEntryIdx][year] =
              testsByMonth[yearAndMonth];
          }
        }
      }
    }

    tempTestCountData = tempTestCountData.sort((a, b) => a.count - b.count);

    setYears(tempYears);
    setTestCountData(tempTestCountData);
  }, [testsByMonth]);

  return (
    <Container>
      <Typography variant="h2" style={{ marginTop: '1em' }}>
        {title}
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2em',
        }}
      >
        <ResponsiveContainer width="99%" aspect={3}>
          <BarChart data={testCountData}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            {years.map((year, idx) => (
              <Bar key={idx} dataKey={year} fill={primaryColor.main} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

YearOverYearTestsGraph.propTypes = {
  title: PropTypes.string.isRequired,
};

export default YearOverYearTestsGraph;
