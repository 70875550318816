import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Refresh as RefreshIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { setError } from '../../../../alerts';

// Components
import { Accordion, AccordionSummary, AccordionDetails, Container, Typography, CircularProgress, IconButton } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import { DARKEST_GREY } from '@parasightsysteminc/companion-react';

import { getSensorData } from '../../../../api';

class InstrumentSensors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      sensorData: null,
      error: null,
    };
    this.hasMountedAlready = false;
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      this.retrieveSensorData();
    }
    this.hasMountedAlready = true;
  }

  retrieveSensorData = () => {
    this.setState({ isLoading: true });

    getSensorData(this.props.match?.params?.serialNumber).then((sensorData) => {
      this.setState({ isLoading: false, sensorData });
    }).catch((error) => {
      setError(error ?? 'Error retrieving sensors');
      this.setState({ isLoading: false, error });
    });
  };

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>Instrument Sensors</Typography>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
          <IconButton style={{ backgroundColor: '#606060', color: 'white', marginLeft: '10px', opacity: this.state.isLoading ? 0.5 : 1 }} onClick={this.retrieveSensorData} disabled={this.state.isLoading}><RefreshIcon /></IconButton>
          <Typography variant='body1' style={{ marginLeft: '10px', fontSize: '23px', color: DARKEST_GREY }}>{this.state.isLoading ? 'Retrieving sensors' : (this.state.error == null ? 'Refresh' : 'Error Retrieving Sensors')}{this.state.isLoading && <CircularProgress style={{ marginLeft: '5px', height: '20px', width: '20px' }} />}</Typography>
        </div>

        {!this.state.isLoading && this.state.error != null &&
        <>
          <Typography variant='body1'>{this.state.error.message}</Typography>
        </>}

        {!this.state.isLoading && this.state.error == null &&
        <>
          <div style={{ marginTop: '2em' }}>
            <Typography variant='h2'>Raw Sensor Data</Typography>
            <Typography variant='subtitle2' style={{ color: 'slategrey' }}>*Most recent data listed first</Typography>
            {Object.keys(this.state.sensorData)?.map((key, index) => {
              let valueString = this.state.sensorData[key]?.join(', ');
              return (
                <Accordion key={index} style={{ marginTop: '1em', backgroundColor: 'lightgrey' }} defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h4'>{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='body1'>{valueString}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </>}
      </Container>
    );
  }
}

InstrumentSensors.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentSensors);
