import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { setError, setSuccess } from '../../../../alerts';
import PropTypes from 'prop-types';

// Components
import { Typography, IconButton, ToggleButton, ToggleButtonGroup, TextField, MenuItem, Grid, List, ListItem, ListItemButton, Paper, Divider } from '@mui/material';
import { BackToButton, Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { getTestType, deleteRecipe, listTestTypeRecipes } from '../../../../api';

// Icons
import { Add, Delete, Visibility } from '@mui/icons-material/';

import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../../alerts/confirm';

// Constants
import { CONTINENTS } from '@parasightsysteminc/companion-react';
const TABS = {
  ACTIVE: 'Active',
  ALL: 'All'
};

const getContinentLabel = (continent) => {
  const arr = continent.split('_');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substr(1).toLowerCase();
  }
  return arr.join(' ');
};

const getColumns = ({ cancelConfirm, backTo }) => {
  return [
    {
      title: 'Continent',
      remoteField: 'continent',
      value: rowData => rowData.getContinent(),
      render: rowData => getContinentLabel(rowData.getContinent()),
      search: 'select',
      selectOptions: CONTINENTS.map(value => ({ value, label: getContinentLabel(value) })),
    },
    {
      title: 'ID',
      remoteField: 'id',
      value: rowData => rowData.getId(),
      search: 'column'
    },
    {
      title: 'Release Date',
      remoteField: 'releaseDate',
      value: rowData => rowData.getReleaseDate().toLocaleString()
    },
    {
      title: 'Created By',
      remoteField: 'addedByAdminUserId',
      value: rowData => rowData.getAddedByAdminUserId(),
      render: rowData => rowData.getAdminUser()?.getFirstName() + ' ' + rowData.getAdminUser()?.getLastName()
    },
    {
      title: 'Actions',
      omitFromExport: true,
      render: (rowData) => (
        <>
          <Link to={{ pathname: `/recipes/${rowData.getId()}`, state: { backTo } }} ><IconButton><Visibility /></IconButton></Link>
          <IconButton onClick={() => cancelConfirm(rowData)}><Delete /></IconButton>
        </>
      )
    }
  ];
};
const DEFAULT_STATE = {
  selectedTab: TABS.ACTIVE,
  continent: 'NORTH_AMERICA',
  isLoading: false,
  testType: null,
  timesUpdated: 0,
  recipe0: null,
  recipe1: null,
};

class TestType extends Component {
  constructor(props) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    this.retrieveTestType();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(DEFAULT_STATE, () => this.retrieveTestType());
    }
  }

  retrieveTestType = () => {
    // Retrieve the treatment id from the url
    const testTypeId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getTestType(testTypeId).then(testType => {
      this.setState({ isLoading: false, testType });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve treatment info. Please try again.');
      this.setState({ isLoading: false });
    });

    this.retrieveRecipes();
  }

  retrieveRecipes = () => {
    const testTypeId = this.props.match?.params?.id;
    listTestTypeRecipes(testTypeId, { options: { where: { continent: this.state.continent, isActive: true }, order: [['releaseDate', 'desc']] } }).then(({ results }) => {
      const recipe0 = results.find(recipe => recipe.getOrder() === 0);
      const recipe1 = results.find(recipe => recipe.getOrder() === 1);
      this.setState({ recipe0, recipe1 });
    }).catch((error) => {
      setError(error ?? 'Error: Unable to retrieve recipes.');
    });
  }

  onContinentChange = (e) => {
    const continent = e.target.value;
    this.setState({ continent }, () => this.retrieveRecipes());
  }

  cancelConfirm = (recipe) => {
    confirm({
      title: 'Delete Recipe',
      body: 'Are you sure you want to delete this recipe? You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deleteRecipe(recipe.getId()).then(() => {
          setSuccess('Successfully deleted recipe!');
          this.setState({ timesUpdated: this.state.timesUpdated + 1 });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to delete recipe.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  };

  render() {
    const backTo = { pathname: this.props.location, description: 'Recipes' };
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.testType?.getName()}</Typography>
        {this.state.testType?.getIsDemo() && <Typography variant='subtitle1' style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Training Test Type</Typography>}

        {this.state.testType?.getChildTestTypes().length > 0 && (
          <>
            <Typography variant='h3' style={{ textAlign: 'center', marginTop: '20px', marginBottom: '1em' }}>Child Test Types</Typography>

            <Paper elevation={2}>
              <List>
                {this.state.testType.getChildTestTypes().map((testType, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemButton component={Link} to={{ pathname: `/tests/types/${testType.getId()}`, state: { backTo } }}>{testType.getName()}</ListItemButton>
                    </ListItem>
                    {index < this.state.testType.getChildTestTypes()?.length - 1 && <Divider />}
                  </div>
                ))}
              </List>
            </Paper>
          </>
        )}

        {this.state.testType?.getJointChildTestTypes().length > 0 && (
          <>
            <Typography variant='h3' style={{ textAlign: 'center', marginTop: '20px', marginBottom: '1em' }}>Joint Child Test Types</Typography>

            <Paper elevation={2}>
              <List>
                {this.state.testType.getJointChildTestTypes().map((testType, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemButton component={Link} to={{ pathname: `/tests/types/${testType.getId()}`, state: { backTo } }}>{testType.getName()}</ListItemButton>
                    </ListItem>
                    {index < this.state.testType.getJointChildTestTypes()?.length - 1 && <Divider />}
                  </div>
                ))}
              </List>
            </Paper>
          </>
        )}

        {this.state.testType?.getChildTestTypes().length === 0 && this.state.testType?.getJointChildTestTypes().length === 0 && (
          <>
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex', marginBottom: '1em', marginTop: '1em' }}>
              <ToggleButtonGroup
                value={this.state.selectedTab}
                exclusive
                onChange={(_, selectedTab) => {
                  this.setState({ selectedTab });
                  this.retrieveRecipes();
                }}>
                <ToggleButton value={TABS.ACTIVE} style={{ width: '100px' }}>Active</ToggleButton>
                <ToggleButton value={TABS.ALL} style={{ width: '100px' }}>All</ToggleButton>
              </ToggleButtonGroup>
            </div>

            {this.state.selectedTab === TABS.ACTIVE ? (
              <>
                <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Active Recipe</Typography>

                <TextField
                  required
                  select
                  label='Continent'
                  style={{ width: '100%', marginBottom: '20px' }}
                  value={this.state.continent}
                  onChange={this.onContinentChange}
                  variant='filled'
                  disabled={this.state.isLoading}>
                  <MenuItem value='NORTH_AMERICA'>North America</MenuItem>
                  <MenuItem value='SOUTH_AMERICA'>South America</MenuItem>
                  <MenuItem value='EUROPE'>Europe</MenuItem>
                  <MenuItem value='ASIA'>Asia</MenuItem>
                  <MenuItem value='AUSTRALIA'>Australia</MenuItem>
                  <MenuItem value='ANTARCTICA'>Antarctica</MenuItem>
                  <MenuItem value='AFRICA'>Africa</MenuItem>
                </TextField>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {this.state.recipe0 != null ? (
                      <>
                        {this.state.testType?.getHasIncubation() &&
                        <Typography variant='h3' style={{ marginBottom: '0.5em', textAlign: 'center' }}>Before Incubation</Typography>}
                        <Typography variant='body1' style={{ textAlign: 'center' }}><b>ID</b>: {this.state.recipe0.getId()}</Typography>
                        <Typography variant='body1' style={{ textAlign: 'center' }}><b>Release Date</b>: {this.state.recipe0.getReleaseDate()?.toLocaleString()}</Typography>
                        <TextField
                          label='Recipe Script'
                          fullWidth
                          multiline
                          rows={30}
                          style={{ marginTop: '0.5em' }}
                          value={this.state.recipe0.getScriptContent()}
                          variant='filled'
                          disabled={true}
                        />
                      </>
                    ) : (
                      <Typography variant='body1'>No active recipe found.</Typography>
                    )}
                    <Button component={Link} to={{ pathname: '/recipes/create', search: `?testTypeId=${this.props.match?.params?.id}&order=0&continent=${this.state.continent}`, state: { backTo } }} style={{ marginTop: '20px', width: '100%' }}>Create New</Button>
                  </Grid>

                  {this.state.testType?.getHasIncubation() &&
                  <Grid item xs={12} md={6}>
                    {this.state.recipe1 != null ? (
                      <>
                        <Typography variant='h3' style={{ marginBottom: '0.5em', textAlign: 'center' }}>After Incubation</Typography>
                        <Typography variant='body1' style={{ textAlign: 'center' }}><b>ID</b>: {this.state.recipe1.getId()}</Typography>
                        <Typography variant='body1' style={{ textAlign: 'center' }}><b>Release Date</b>: {this.state.recipe1.getReleaseDate()?.toLocaleString()}</Typography>
                        <TextField
                          label='Recipe Script'
                          fullWidth
                          multiline
                          rows={30}
                          style={{ marginTop: '0.5em' }}
                          value={this.state.recipe1.getScriptContent()}
                          variant='filled'
                          disabled={true}
                        />
                      </>
                    ) : (
                      <Typography variant='body1'>No active recipe found.</Typography>
                    )}
                    <Button component={Link} to={{ pathname: '/recipes/create', search: `?testTypeId=${this.props.match?.params?.id}&order=1&continent=${this.state.continent}`, state: { backTo } }} style={{ marginTop: '20px', width: '100%' }}>Create New</Button>
                  </Grid>}
                </Grid>
              </>
            ) : (
              <>
                <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Recipes</Typography>

                <MaterialTable
                  key={this.state.timesUpdated}
                  title='Recipes'
                  isLoading={this.state.isLoading}
                  headerContent={
                    <Button component={Link} to={{ pathname: '/recipes/create', search: `?testTypeId=${this.props.match?.params?.id}`, state: { backTo } }}>
                      Create New
                      <Add style={{ marginLeft: '5px' }} />
                    </Button>
                  }
                  data={{
                    mode: 'remote',
                    columns: getColumns({ cancelConfirm: this.cancelConfirm, backTo }),
                    fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
                      new Promise((resolve, reject) => {
                        let options = {
                          offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                          limit: rowsPerPage,
                          where,
                          order,
                        };
                        listTestTypeRecipes(this.props.match?.params?.id, { options }).then((res) => {
                          resolve(res);
                        }).catch((error) => {
                          reject(error);
                        });
                      })
                  }}
                  onError={(error) => {
                    setError(error);
                  }}
                  options={{
                    flipHorizontalScroll: true,
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

TestType.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(TestType);
