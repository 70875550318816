import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setError, setSuccess } from '../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../alerts/confirm';

// Components
import { IconButton, Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listAdminUsers, listAdminUserInvitations, resendAdminInvitation, cancelAdminInvitation } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';  // Resend Icon
import DeleteIcon from '@mui/icons-material/Delete';

// Constants
const red = '#d32f2f';
const getAdminUserColumns = ({ locationState }) => {
  return ([
    {
      title: 'First Name',
      remoteField: 'firstName',
      value: rowData => rowData.getFirstName(),
      search: 'column'
    },
    {
      title: 'Last Name',
      remoteField: 'lastName',
      value: rowData => rowData.getLastName(),
      search: 'column'
    },
    {
      title: 'Email',
      remoteField: 'email',
      value: rowData => rowData.getEmail(),
      search: 'column',
    },
    {
      title: 'Suspended',
      remoteField: 'isSuspended',
      value: rowData => rowData.getIsSuspended(),
      render: (rowData) => {
        return (rowData.isSuspended ? (<span style={{ color: red }}>Suspended</span>) : (<span style={{ color: 'green' }}>Active</span>));
      },
      search: 'select',
      selectOptions: [{ value: true, label: 'Suspended' }, { value: false, label: 'Active' }]
    },
    {
      title: 'View',
      omitFromExport: true,
      render: (rowData) => (
        <Link to={{ pathname: `/admin-users/${rowData.getId()}`, state: locationState }}><VisibilityIcon /></Link>
      )
    }]);
};

const getAdminUserInvitationColumns = (resendConfirm, cancelConfirm) => {
  return ([
    {
      title: 'Email',
      remoteField: 'email',
      value: rowData => rowData.getEmail(),
      search: 'column',
    },
    {
      title: 'Actions',
      omitFromExport: true,
      render: (rowData) => (
        <>
          <IconButton onClick={() => resendConfirm(rowData)}><ReplayIcon /></IconButton>
          <IconButton onClick={() => cancelConfirm(rowData)}><DeleteIcon /></IconButton>
        </>
      )
    }
  ]);
};


class AdminUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timesUpdated: 0,
      searchString: null,
    };
  }

  resendConfirm = (adminUserInvitation) => {
    confirm({
      title: 'Resend Admin User Invitation',
      body: `Are you sure you want to resend the admin user invitation to ${adminUserInvitation.email}?`,
      onConfirm: () => {
        startConfirmLoading();
        resendAdminInvitation(adminUserInvitation.id).then(() => {
          setSuccess('Successfully resent invitation email!');
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to resend invitation email.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  };

  cancelConfirm = (adminUserInvitation) => {
    confirm({
      title: 'Delete Admin User Invitation',
      body: `Are you sure you want to delete the admin user invitation for ${adminUserInvitation.getEmail()}?`,
      onConfirm: () => {
        startConfirmLoading();
        cancelAdminInvitation(adminUserInvitation.id).then(() => {
          setSuccess('Successfully canceled invitation email!');
          this.setState({ timesUpdated: this.state.timesUpdated + 1 });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to cancel invitation email.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  };

  render() {
    const locationState = {
      backTo: { pathname: { pathname: '/admin-users', state: { tableSearchString: this.state.searchString } }, description: 'All Admin Users' }
    };
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Admin Users</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Admin Users</Typography>

        <MaterialTable
          title='Admin Users'
          headerContent={
            <Button component={Link} to={{ pathname: '/admin-users/invite', state: locationState }}>
              Invite New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'remote',
            columns: getAdminUserColumns({ locationState }),
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listAdminUsers({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />

        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Admin User Invitations</Typography>

        <MaterialTable
          key={this.state.timesUpdated}
          title='Admin User Invitations'
          headerContent={
            <Button component={Link} to={{ pathname: '/admin-users/invite', state: locationState }}>
              Invite New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'remote',
            columns: getAdminUserInvitationColumns(this.resendConfirm, this.cancelConfirm),
            fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listAdminUserInvitations({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            hideExport: true,
            flipHorizontalScroll: true,
          }}
        />
      </div>
    );
  }
}

AdminUsers.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(AdminUsers);
