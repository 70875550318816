import React, { Component } from 'react';
import { Container, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// API
import { checkInvitation } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';
import { connect } from 'react-redux';
import { createUserAccount } from '../../../../redux/actions/authActions';

class CreateAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      redirectToLogin: false,
      token: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const token = this.props.match?.params?.token;

    checkInvitation({ token }).then(adminUserInvitation => {
      this.setState({ isLoading: false, email: adminUserInvitation?.getEmail(), token });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrive user invitation.');
      this.setState({ isLoading: false, redirectToLogin: true });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      firstName,
      lastName,
      token,
      password,
      confirmPassword
    } = this.state;

    if (password != confirmPassword) {
      setError('Error: Passwords must match');
      this.setState({ isLoading: false });
      return;
    }

    // Tell redux to create account
    this.props.authenticateUser(firstName, lastName, password, token);

    this.setState({ isLoading: false });
  };

  render() {
    return this.state.redirectToLogin ? <Redirect to='/auth/login' /> : (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Create Account</Typography>

        <form onSubmit={this.onSubmit}>
          <Typography variant='h2' style={{ textAlign: 'left', marginTop: '1em', marginBottom: '0.5em' }}>Personal Information</Typography>
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '0.5em' }}>
              <TextField
                required
                name='firstName'
                label='First Name'
                value={this.state.firstName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                style={{ width: '49%' }}
              />

              <TextField
                required
                name='lastName'
                label='Last Name'
                value={this.state.lastName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                style={{ width: '49%' }}
              />
            </div>
            <TextField
              required
              name='email'
              label='Email'
              type='email'
              value={this.state.email}
              onChange={this.onChange}
              variant='filled'
              disabled={true}
              style={{ width: '100%', marginBottom: '0.5em' }}
            />
          </>

          <Typography variant='h2' style={{ textAlign: 'left', marginTop: '1em', marginBottom: '0.5em' }}>Password</Typography>
          <>
            {/* Password */}
            <TextField
              required
              name='password'
              label='Password'
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              fullWidth
              helperText='Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              style={{ marginBottom: '0.5em' }}
            />
            <TextField
              required
              name='confirmPassword'
              label='Confirm Password'
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.confirmPassword}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              style={{ marginBottom: '0.5em' }}
            />
          </>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '40px',
            }}
          >
            <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
              Submit
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

CreateAccount.propTypes = {
  match: PropTypes.object.isRequired,
  authenticateUser: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  authenticateUser: (firstName, lastName, password, token) => createUserAccount(dispatch, firstName, lastName, password, token)
});

export default connect(undefined, mapDispatchToProps)(withRouter(CreateAccount));
