import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import ReactPhoneInput from 'react-phone-input-material-ui';

// APIs
import { createPractice, updatePractice } from '../../api';

import { setError, setSuccess } from '../../alerts';

// CONSTANTS
import { STATES_PROVINCES, COUNTRIES, CONTINENTS, TIME_ZONES, Address } from '@parasightsysteminc/companion-react';

class PracticeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      identifier: '',
      name: '',
      contactName: '',
      contactEmail: '',
      contactPhoneNumber: '',
      address: null,
      isLoading: false,
      isDemo: false,
    };
  }

  componentDidMount() {
    // If this is an edit form, pre-fill the form
    if (this.props.practice != null) {
      this.setState({
        identifier: this.props.practice.getIdentifier(),
        name: this.props.practice.getName() ?? '',
        contactName: this.props.practice.getContactName() ?? '',
        contactEmail: this.props.practice.getContactEmail() ?? '',
        contactPhoneNumber: this.props.practice.getContactPhoneNumber() ?? '',
        address: Address.freeze(this.props.practice.getAddress()) ?? {}
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      identifier,
      name,
      contactName,
      contactEmail,
      contactPhoneNumber,
      address,
      isDemo
    } = this.state;

    // In Edit Mode
    if (this.props.practice != null) {
      delete address.id;
      updatePractice({ id: this.props.practice.getId(), identifier, name, contactName, contactEmail, contactPhoneNumber, address, isDemo }).then(practice => {
        setSuccess('Successfully updated practice!');
        this.setState({ isLoading: false });
        this.props.onSuccess(practice);
      }).catch(error => {
        setError(error ?? 'Error: Unable to update practice. Please try again.');
        this.setState({ isLoading: false });
      });
    }

    // In Creation Mode
    else {
      delete address.id;
      createPractice({ identifier, name, contactName, contactEmail, contactPhoneNumber, address, isDemo }).then(practice => {
        setSuccess('Successfully created practice!');
        this.setState({ isLoading: false });
        this.props.onSuccess(practice);
      }).catch(error => {
        setError(error ?? 'Error: Unable to create practice. Please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onAddressChange = (e) => {
    this.setState({ address: { ...this.state.address, [e.target.name]: e.target.value } });
  }

  onStateChange = (_, newState) => {
    this.setState({ address: { ...this.state.address, state: newState } });
  }

  onCountryChange = (_, newCountry) => {
    this.setState({ address: { ...this.state.address, country: newCountry } });
  }

  onContinentChange = (_, newContinent) => {
    this.setState({ address: { ...this.state.address, continent: newContinent } });
  }

  onTimezoneChange = (_, newTimezone) => {
    this.setState({ address: { ...this.state.address, timeZone: newTimezone } });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Practice Info</Typography>
        <>
          {/* Identifier */}
          <TextField
            required
            name='identifier'
            label='Identifier'
            value={this.state.identifier}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em'  }}
          />

          {/* Name */}
          <TextField
            required
            name='name'
            label='Name'
            value={this.state.name}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em'  }}
          />

          <div style={{ height: '3.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControlLabel style={{ marginBottom: '0.5em' }}
              control={<Checkbox defaultChecked={false} />}
              onChange={() => this.setState({ isDemo: !this.state.isDemo })}
              name='isDemo'
              label="This practice is a TRAINING practice. All tests performed by this practice should use a fake recipe and generate fake results."
              defaultChecked={false}
            />
          </div>
        </>

        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Contact Info</Typography>
        <>

          {/* Contact Name */}
          <TextField
            required
            name='contactName'
            label='Contact Name'
            value={this.state.contactName}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Contact Email */}
          <TextField
            required
            name='contactEmail'
            label='Contact Email'
            type='email'
            value={this.state.contactEmail}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Contact Phone Number */}
          <ReactPhoneInput
            country='us'
            required
            onlyCountries={['us']}
            value={this.state.contactPhoneNumber}
            onChange={(contactPhoneNumber) => this.setState({ contactPhoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Contact Phone Number', disabled: this.state.isLoading }}
            disableCountryCode={true}
            disableDropdown={true}
            placeholder='(702) 123-4567'
            containerStyle={{ width: '100%', marginBottom: '20px' }}
          />

        </>

        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Practice Location</Typography>
        <>

          {/* Address Line 1 */}
          <TextField
            required
            name='line1'
            label='Line 1'
            value={this.state.address?.line1 ?? ''}
            onChange={this.onAddressChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Address Line 2 */}
          <TextField
            name='line2'
            label='Line 2'
            value={this.state.address?.line2 ?? ''}
            onChange={this.onAddressChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '0.5em' }}>
            {/* City */}
            <TextField
              required
              name='city'
              label='City'
              value={this.state.address?.city ?? ''}
              onChange={this.onAddressChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '48%' }}
            />

            {/* State */}
            <Autocomplete
              disablePortal
              options={STATES_PROVINCES}
              style={{ width: '30%' }}
              disabled={this.state.isLoading}
              value={this.state.address?.state ?? null}
              onChange={this.onStateChange}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label='State'
                  variant='filled'
                  name='country'
                  disabled={this.state.isLoading}
                />
              )}
            />

            {/* Zip */}
            <TextField
              required
              name='zip'
              label='ZIP'
              value={this.state.address?.zip ?? ''}
              onChange={this.onAddressChange}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '20%' }}
            />
          </div>

          {/* Country */}
          <Autocomplete
            disablePortal
            options={COUNTRIES}
            style={{ width: '100%', marginBottom: '0.5em' }}
            disabled={this.state.isLoading}
            value={this.state.address?.country ?? null}
            onChange={this.onCountryChange}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Country'
                variant='filled'
                name='country'
                disabled={this.state.isLoading}
              />
            )}
          />

          {/* Continent */}
          <Autocomplete
            disablePortal
            options={CONTINENTS}
            style={{ width: '100%', marginBottom: '0.5em' }}
            disabled={this.state.isLoading}
            value={this.state.address?.continent ?? null}
            onChange={this.onContinentChange}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Continent'
                variant='filled'
                name='continent'
                disabled={this.state.isLoading}
              />
            )}
          />

          {/* Time Zone */}
          <Autocomplete
            disablePortal
            options={TIME_ZONES}
            style={{ width: '100%' }}
            disabled={this.state.isLoading}
            value={this.state.address?.timeZone ?? null}
            onChange={this.onTimezoneChange}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Time Zone'
                variant='filled'
                name='timezone'
                disabled={this.state.isLoading}
              />
            )}
          />

        </>

        {/* Form Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          {/* Submit Button */}
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '48%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

PracticeForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  practice: PropTypes.object,
  address: PropTypes.object,
  onClose: PropTypes.func
};

export default PracticeForm;
