import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Container, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { PieChartActiveSegment } from '../PieChartActiveSegment';
import { countTestsBySpecies } from '../../api';
import { setError } from '../../alerts';

const COLORS = [
  'Tomato',
  'MediumSeaGreen',
  'Orange',
  'DodgerBlue',
  'SlateBlue',
  'DarkGreen',
  'IndianRed',
  'Lavender',
];

const SpeciesTestsChart = ({ title }) => {
  // State Declarations
  // API output:
  const [testCountBySpecies, setTestCountBySpecies] = useState({});
  // Data for chart element:
  const [testCounts, setTestCounts] = useState([]);
  // Used to track which part of the pie chart is being hovered over
  const [activePieChartIndex, setActivePieChartIndex] = useState();

  const {
    palette: { primary: primaryColor },
  } = useTheme();

  // Get the data from the API on first render:
  useEffect(() => {
    countTestsBySpecies()
      .then((species) => {
        setTestCountBySpecies(species);
      })
      .catch(setError);
  }, []);

  // Build the table for the chart whenever the API's output changes:
  useEffect(() => {
    let tempTestCountData = [];
    for (const testID in testCountBySpecies) {
      if (Object.hasOwnProperty.call(testCountBySpecies, testID)) {
        tempTestCountData.push({
          testID: testID,
          count: testCountBySpecies[testID],
        });
      }
    }

    setTestCounts(tempTestCountData);
  }, [testCountBySpecies]);

  return (
    <Container>
      <Typography variant="h2" style={{ marginTop: '1em' }}>
        {title}
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2em',
        }}
      >
        <ResponsiveContainer width="99%" aspect={3}>
          <PieChart>
            <Pie
              dataKey="count"
              nameKey="testID"
              fill={primaryColor.main}
              activeIndex={activePieChartIndex}
              activeShape={(props) => (
                <PieChartActiveSegment
                  {...props}
                  payloadKey="testID"
                  countTitle="Total Count"
                  showPercent
                />
              )}
              data={testCounts}
              innerRadius="50%"
              onMouseEnter={(_, index) => {
                setActivePieChartIndex(index);
              }}
              onMouseLeave={() => {
                setActivePieChartIndex(undefined);
              }}
            >
              {testCounts.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                );
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

SpeciesTestsChart.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SpeciesTestsChart;
