import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Grid, Typography } from '@mui/material';

// APIs
import { getTest, auditTest } from '../../../../api';

import { setError, setSuccess } from '../../../../alerts';

// Components
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../../alerts/confirm';

class TestAudit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      test: null,
      isLoadingIssue: false,
      isLoadingNoIssue: false
    };
  }

  componentDidMount() {
    this.retrieveTest();
  }

  retrieveTest = () => {
    // Retrieve the test id from the url
    const testId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getTest(testId).then(test => {
      this.setState({ isLoading: false, test });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test info. Please try again.');
    });
  }

  confirmNoIssues = () => {
    this.setState({ isLoadingNoIssue: true });
    confirm({
      title: 'Audit Test',
      body: 'Are you sure you want to mark this test as correct?',
      onConfirm: () => {
        startConfirmLoading();
        auditTest({ id: this.state.test.getId(), issueFlagged: false }).then((test) => {
          setSuccess('Successfully audited test!');
          this.setState({ test, isLoadingNoIssue: false });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to audit test.');
          stopConfirmLoading();
          closeConfirm();
          this.setState({ isLoadingNoIssue: false });
        });
      },
      danger: true
    });
  }

  confirmIssues = () => {
    confirm({
      title: 'Audit Test',
      body: 'Are you sure you want to mark this test as having issues?',
      onConfirm: () => {
        startConfirmLoading();
        this.setState({ isLoadingIssue: true });
        auditTest({ id: this.state.test.getId(), issueFlagged: true }).then((test) => {
          setSuccess('Successfully audited test!');
          stopConfirmLoading();
          closeConfirm();
          this.setState({ test, isLoadingIssue: false });
        }).catch(error => {
          setError(error ?? 'Error: Unable to audit test.');
          stopConfirmLoading();
          closeConfirm();
          this.setState({ isLoadingIssue: false });
        });
      },
      danger: true
    });
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Audit Test</Typography>
        <Typography variant='h6'
          style={{
            textAlign: 'center', marginBottom: '2em',
            color: this.state.test?.getWasAudited() ? (this.state.test?.getIssueFlagged ? ('#d32f2f') : ('green')) : ('#999')
          }}>
          {this.state.test?.getWasAudited() ? (this.state.test?.getIssueFlagged ? ('ISSUES FLAGGED') : ('NO ISSUES FOUND')) : ('NOT YET AUDITED')}
        </Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <img src={this.state.test?.getRawImagePath()} style={{ width: '100%' }}></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={this.state.test?.getMaskedCircledImagePath()} style={{ width: '100%' }}></img>
              </Grid>
            </Grid>

            <div style={{ marginTop: '2em' }}>
              {this.state.test?.getTestedParasites()?.map((object, i) => (<LineItem key={i} value={object.getCount()} description={object.getParasiteSpecies()?.getName()} />))}

            </div>

            <div style={{ marginTop: '50px' }}>
              <Typography variant='h3' style={{ marginBottom: '1em' }} >Does this pass inspection?</Typography>
              <Button onClick={this.confirmNoIssues} isLoading={this.state.isLoadingNoIssue} disabled={this.state.isLoadingIssue}>Yes</Button>
              <Button danger onClick={this.confirmIssues} isLoading={this.state.isLoadingIssue} disabled={this.state.isLoadingNoIssue} style={{ marginLeft: '1em' }}>No</Button>
            </div>
          </>
        )}
      </Container>
    );
  }
}

TestAudit.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(TestAudit);
