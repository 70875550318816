import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, withRouter } from 'react-router-dom';
import { MaterialTable } from '@lexcelon/react-util';

import { listErrorLogs } from '../../../api';

import { Typography } from '@mui/material';

const COLUMNS = ({ locationState }) => ([
  {
    value: rowData => rowData.getReportedTimestamp().toLocaleString(),
    title: 'Reported At',
    remoteField: 'reportedTimestamp',
    search: 'date'
  },
  {
    value: rowData => rowData.getErrorCode(),
    title: 'Error Code'
  },
  {
    value: rowData => rowData.getTestId(),
    title: 'Test ID',
    remoteField: 'testId',
    search: 'range'
  },
  {
    value: rowData => rowData.getAction(),
    title: 'Action',
    remoteField: 'action',
    search: 'column',
  },
  {
    value: rowData => rowData.getInstrumentSerialNumber(),
    title: 'Instrument',
    remoteField: 'instrumentSerialNumber',
    search: 'column'
  },
  {
    render: rowData => <Link to={{ pathname: `/practices/${rowData.getPracticeId()}`, state: locationState }}>{rowData.getPracticeName()}</Link>,
    value: rowData => rowData.getPracticeName(),
    title: 'Practice',
  },
  {
    value: rowData => rowData.getUserFullName(),
    title: 'User',
  },
  {
    value: rowData => rowData.getAdditionalInfo(),
    title: 'Additional Info',
    remoteField: 'additionalInfo',
    search: 'column'
  }
]);

class PracticeErrorLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: null
    };
  }

  render() {
    const locationState = {
      backTo: { pathname: { pathname: '/errors/log', state: { tableSearchString: this.state.searchString } }, description: 'Error Log' }
    };
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Error Log</Typography>
        <MaterialTable
          title={''}
          data={{
            mode: 'remote',
            columns: COLUMNS({ locationState }),
            fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>

              new Promise((resolve, reject) => {
                const options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listErrorLogs({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />
      </div>
    );
  }
}

PracticeErrorLog.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(PracticeErrorLog);