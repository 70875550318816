import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import { Button, LineItem } from '@lexcelon/react-util';

// API
import { getSelf } from '../../api';

import { PasswordChangeForm } from '../../components';

// Alerts
import { setError, setSuccess } from '../../alerts';

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user: null,
      inPasswordEditMode: false
    };
  }

  componentDidMount() {
    this.retrieveUser();
  }

  retrieveUser = () => {
    this.setState({ isLoading: true });
    getSelf().then(user => {
      this.setState({ isLoading: false, user });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve user info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openPasswordEditMode = () => {
    this.setState({ inPasswordEditMode: true });
  }

  closePasswordEditMode = () => {
    this.setState({ inPasswordEditMode: false });
  }

  onPasswordChangeSuccess = () => {
    this.retrieveUser();
    setSuccess('Successfully changed password!');
    this.closePasswordEditMode();
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Account Settings</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Personal Information</Typography>
        <>
          {/* Name */}
          <LineItem
            value={this.state.user?.getFirstName() + ' ' + this.state.user?.getLastName()}
            description='Name'
          />

          {/* Email */}
          <LineItem
            value={this.state.user?.getEmail()}
            description='Email'
          />

        </>

        {/* Password */}
        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Password</Typography>
        {this.state.inPasswordEditMode ? (
          <PasswordChangeForm
            onSuccess={this.onPasswordChangeSuccess}
            onClose={this.closePasswordEditMode}
            user={this.state.user}
          />
        ) : (
          <Button secondary onClick={this.openPasswordEditMode}>Change Password</Button>
        )}

      </Container>
    );
  }
}

export default AccountSettings;