import React, { Component } from 'react';
import { Typography } from '@mui/material';


// Alerts
import { setError } from '../../../alerts';


// Components
import { MaterialTable } from '@lexcelon/react-util';

import { listKnownErrors } from '../../../api';

const COLUMNS = [
  {
    value: rowData => rowData.getCode(),
    title: 'Code'
  },
  {
    value: rowData => rowData.getMessage(),
    title: 'Message'
  },
  {
    value: rowData => rowData.getDescription(),
    title: 'Description'
  },
  {
    value: rowData => rowData.getTroubleshootMessage(),
    title: 'Troubleshoot Message'
  },
  {
    value: rowData => rowData.getIsActive() ? 'Yes' : 'No',
    title: 'Active'
  },
];

class KnownErrors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      KnownErrors: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listKnownErrors().then((results) => {
      this.setState({ KnownErrors : results, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve known errors. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Known Errors</Typography>
        <MaterialTable
          title={''}
          data={{
            mode: 'list',
            rows: this.state.KnownErrors,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

KnownErrors.propTypes = {
};

export default KnownErrors;