import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography,  TextField, InputAdornment, IconButton } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { Link } from 'react-router-dom';
import { Logo } from '@parasightsysteminc/companion-react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

//ALERTS
import { authenticateUser } from '../../../redux/actions/authActions';
import { connect } from 'react-redux';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showPassword: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    this.props.authenticateUser(this.state.email, this.state.password);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Logo} style={{ width: '12em', marginTop: '2.5em' }}></img>
        </div>
        
        <form onSubmit={this.onSubmit}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Login</Typography>

          {/* Email */}
          <TextField
            required
            name='email'
            label='Email

            '
            type='email'
            onChange={this.onChange}
            disabled={this.props.isLoading}
            variant='filled'
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Password */}
          <TextField
            required
            name='password'
            label='Password'
            type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.onChange}
            disabled={this.props.isLoading}
            variant='filled'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Submit */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type='submit'
              style={{ width: '48%', marginTop: '1em' }} isLoading={this.state.isLoading}>
              Submit
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.5em' }}>
            Forgot your password?&nbsp;<Link to='/auth/forgot-password/request'>Reset it now.</Link>
          </div>

        </form>
      </Container>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  authenticateUser: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: (username, password) => authenticateUser(dispatch, username, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
