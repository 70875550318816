import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setError } from '../../../../alerts';

// Components
import { Container, Typography, TextField, Grid } from '@mui/material';
import { BackToButton, Button } from '@lexcelon/react-util';

import { getLogData } from '../../../../api';

class InstrumentLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      redirect: false,
      fileName: 'cad.log',
      log: null,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    getLogData(this.props.match?.params?.serialNumber, { fileName: this.state.fileName }).then(data => {
      this.setState({ isLoading: false });
      if (data.filename == null || data.filename === '') setError('No logs found for the specified file name.');
      else this.setState({ log: data });
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error ?? 'An error occurred while retrieving the logs.');
    });
  };

  render() {
    if (this.state.redirect) return <Redirect to='/' />;
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Instrument {this.props.match?.params?.serialNumber} Logs</Typography>

        <form onSubmit={this.onSubmit}>
          <Grid container style={{ width: '100%' }} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                value={this.state.fileName}
                onChange={(e) => this.setState({ fileName: e.target.value })}
                variant='filled'
                disabled={this.state.isLoading}
                style={{ width: '100%' }}
                placeholder='cad.log.YYYY.MM.DD'
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type='submit' style={{ width: '100%' }} isLoading={this.state.isLoading}>Retrieve</Button>
            </Grid>
          </Grid>
        </form>

        {this.state.log != null &&
        <>
          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '1em' }}>{this.state.log.filename}</Typography>
          <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>{this.state.log.logs}</Typography>
        </>}
      </Container>
    );
  }
}

InstrumentLogs.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentLogs);
