import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

import { RecipeForm } from '../../../components';
import { BackToButton } from '@lexcelon/react-util';

class CreateRecipe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    return this.state.redirect ? <Redirect to={this.props.location?.state?.backTo?.pathname ?? '/'} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Create Recipe</Typography>

        <RecipeForm
          onSuccess={() => this.setState({ redirect: true })}
        />
      </Container>
    );
  }
}

CreateRecipe.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(CreateRecipe);
