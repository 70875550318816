import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import { InstrumentSettingsForm } from '../../../../components';

import { getShuttleSettings, setShuttleSettings } from '../../../../api';

// Values can be number or boolean
const SETTINGS = [
  {
    label: 'Current Position',
    key: 'currentPosition',
    type: 'number',
    disabled: true
  },
  {
    label: 'Acceleration',
    key: 'acceleration',
    type: 'number'
  },
  {
    label: 'Homing Velocity',
    key: 'homingVelocity',
    type: 'number'
  },
  {
    label: 'Camera Position',
    key: 'cameraPosition',
    type: 'number'
  },
  {
    label: 'Load Position',
    key: 'loadPosition',
    type: 'number'
  },
  {
    label: 'Fluid 1 Position',
    key: 'fluid1Position',
    type: 'number'
  },
  {
    label: 'Fluid 2 Position',
    key: 'fluid2Position',
    type: 'number'
  },
  {
    label: 'Fluid 3 Position',
    key: 'fluid3Position',
    type: 'number'
  },
  {
    label: 'Fluid 4 Position',
    key: 'fluid4Position',
    type: 'number'
  },
  {
    label: 'Fluid 5 Position',
    key: 'fluid5Position',
    type: 'number'
  },
  {
    label: 'Fluid 6 Position',
    key: 'fluid6Position',
    type: 'number'
  }
];

class InstrumentShuttleSettings extends Component {

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>Instrument Shuttle Settings</Typography>

        <InstrumentSettingsForm
          settings={SETTINGS}
          getSettings={getShuttleSettings}
          setSettings={setShuttleSettings}
          serialNumber={this.props.match?.params?.serialNumber}
        />
      </Container>
    );
  }
}

InstrumentShuttleSettings.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentShuttleSettings);
