import React, { Component } from 'react';
import { Typography } from '@mui/material';

// Components
import { MaterialTable } from '@lexcelon/react-util';
import { INSTRUMENT_STATUSES } from '@parasightsysteminc/companion-react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { listInstruments } from '../../api';

const COLUMNS = [
  {
    title: 'Serial Number',
    remoteField: 'serialNumber',
    value: rowData => rowData.getSerialNumber(),
    search: 'column'
  },
  {
    title: 'View',
    omitFromExport: true,
    render: (rowData) => (
      <Link to={'/instruments/' + rowData.getSerialNumber()}><VisibilityIcon /></Link>
    )
  },
  {
    title: 'Friendly Name',
    remoteField: 'friendlyName',
    value: rowData => rowData.getFriendlyName() || '',
    search: 'column'
  },
  {
    title: 'Status',
    remoteField: 'statusId',
    value: rowData => rowData.getStatus(),
    search: 'select',
    selectOptions: Object.keys(INSTRUMENT_STATUSES).map(key => ({ value: INSTRUMENT_STATUSES[key], label: key })),
    disableSort: true
  },
  {
    title: 'Practice',
    remoteField: 'practiceId',
    disableSort: true,
    render: rowData => rowData.getPracticeId() == null ? null : (
      <Link to={`/practices/${rowData.getPracticeId()}`}>{rowData.getPractice()?.getName() ?? `Practice ID: ${rowData.getPracticeId()}`}</Link>
    ),
    value: rowData => rowData.getPractice()?.getName() ?? '',
    search: null
  },
];

class Instruments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Instruments</Typography>
        <MaterialTable
          title={''}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>

              new Promise((resolve, reject) => {
                const options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listInstruments({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
        />
      </div>
    );
  }
}

Instruments.propTypes = {
};

export default Instruments;